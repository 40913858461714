<ng-container *ngFor="let item of customPresets">
  <itq-mat-button
    [size]="Sizes.BLOCK"
    [type]="'button'"
    [materialStyle]="(item.label | getSelected: dateRange) ? 'flat' : 'basic'"
    [align]="Align.START"
    [style]="(item.label | getSelected: dateRange) ? 'primary' : undefined"
    (mouseDownEvent)="selectRange(item)"
  >
    {{ DateQueryLabel[item.label] }}
  </itq-mat-button>
</ng-container>
