import { Pipe, PipeTransform } from '@angular/core';
import { IPreset } from '../../../../interfaces/date-range.interface';
import { DateQueryType } from '@intorqa-ui/core';

@Pipe({
  name: 'getSelected',
})
export class GetSelectedPipe implements PipeTransform {
  transform(item: DateQueryType, dateRange: IPreset): boolean {
    if (!dateRange) {
      return item === DateQueryType.Custom ? true : false;
    }
    return item === dateRange.label ? true : false;
  }
}
