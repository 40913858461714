import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { IconType, QueryFilters } from '@intorqa-ui/core';
import { Subscription } from 'rxjs';
import { VirtualScrollService } from './../../services/virtual-scrolling.service';

@Component({
  selector: 'itq-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss'],
})
export class ListComponent implements OnInit, OnDestroy {
  private scrollTop = 0;
  public query: string;
  private subscription = new Subscription();

  // Somewhere in the component's code...
  readonly IconType = IconType;

  @Input() dataSource: Array<any>;
  @Input() template: TemplateRef<unknown>;
  @Input() dataFields: { name: string; value: string };
  @Input() allowDrag = false;
  @Input() initialState = new QueryFilters(
    10,
    1,
    undefined,
    undefined,
    undefined,
  );

  @Output() dataBound = new EventEmitter<void>();
  @Output() add = new EventEmitter<string>();
  @Output() itemClick = new EventEmitter<any>();

  @ViewChild('selectionsList') selectionsList: any;

  constructor(private virtualScrollService: VirtualScrollService) {}

  ngOnInit(): void {
    this.bindDataBoundObservable();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  private bindDataBoundObservable(): void {
    this.subscription.add(
      this.virtualScrollService.dataBoundObservable.subscribe(() => {
        this.scrollTop = 0;
        this.selectionsList?._element?.nativeElement?.scrollTo(0, 0);
      }),
    );
  }

  private onDataBound(): void {
    this.dataBound.emit();
  }

  public onScroll(): void {
    if (this.initialState) {
      this.virtualScrollService
        .scrollDown(
          this.selectionsList._element.nativeElement,
          this.initialState.pageSize,
          this.dataSource.length,
          this.scrollTop,
          undefined,
          this.initialState.page,
        )
        .then((response: { scroll: boolean; scrollTop: number }) => {
          if (response.scroll) {
            this.initialState.page += 1;
            this.scrollTop = response.scrollTop;
            this.onDataBound();
          }
        });
    }
  }

  public onListItemClick(item: any) {
    this.itemClick.emit(item);
  }

  public onDrop(event: CdkDragDrop<string, any>) {
    moveItemInArray(this.dataSource, event.previousIndex, event.currentIndex);
  }
}
