import { EChartsOption } from 'echarts';
import { ChartType } from '../../shared/enums/widget.enum';
import { IDisplayType, ISerie } from '../../shared/interfaces/widget.interface';
import { Widget } from './widget';
import { Chart } from './chart';

export class TableChart extends Chart {
  constructor(public name: ChartType) {
    super(name);
  }

  public getOptions(
    data: Array<ISerie>,
    segment: any,
    settings: { [key: string]: any },
  ): EChartsOption {
    let options: EChartsOption;
    return options;
  }

  public getSelectedType(
    displayTypes: Array<IDisplayType>,
    widget: Widget,
  ): IDisplayType {
    return displayTypes.find((item: IDisplayType) => {
      return item.type === ChartType.TABLE;
    });
  }
}
