import { TagCategory } from '@intorqa-ui/core';
import { TagAnalysis } from '../../boards/models/tag-analysis';
import { TagComparison } from '../../boards/models/tag-comparison';
import { TimeSeries } from '../../boards/models/time-series';
import {
  AnalysisTypes,
  BarMode,
  ChartOrientation,
  ChartType,
  DateInterval,
} from '../enums/widget.enum';
import {
  ITagAnalysis,
  ITagComparison,
  ITimeSeries,
  IWidget,
} from '../interfaces/widget.interface';
import { Query } from '../models/query-model';
import { Timeline } from '../models/timeline';

export class WidgetFactory {
  static createObject(
    widget: IWidget,
  ): TagAnalysis | TagComparison | TimeSeries | Timeline {
    if (widget.type === AnalysisTypes.TAG_ANALYSIS) {
      const tagAnalysis = widget as ITagAnalysis;
      return new TagAnalysis(
        tagAnalysis.widgetId,
        tagAnalysis.username,
        tagAnalysis.type,
        tagAnalysis.name,
        tagAnalysis.description,
        tagAnalysis.width || 30,
        tagAnalysis.height || 5,
        tagAnalysis.x || 0,
        tagAnalysis.y || 0,
        tagAnalysis.options || {
          orientation: ChartOrientation.VERTICAL,
        },
        tagAnalysis.dataSource || [],
        tagAnalysis.dataPoints,
        tagAnalysis.top || 10,
        tagAnalysis.chartType || ChartType.BAR,
        tagAnalysis.ecosystemId,
      );
    } else if (widget.type === AnalysisTypes.TAG_COMPARISON) {
      const tagComparison = widget as ITagComparison;
      return new TagComparison(
        tagComparison.widgetId,
        tagComparison.username,
        tagComparison.type,
        tagComparison.name,
        tagComparison.description,
        tagComparison.width || 30,
        tagComparison.height || 5,
        tagComparison.x || 0,
        tagComparison.y || 0,
        tagComparison.options || {
          orientation: ChartOrientation.VERTICAL,
        },
        tagComparison.dataSource || [],
        tagComparison.dataValues,
        tagComparison.dataType,
        tagComparison.chartType || ChartType.BAR,
        tagComparison.ecosystemId,
      );
    } else if (widget.type === AnalysisTypes.TIME_SERIES) {
      const timeSeries = widget as ITimeSeries;
      return new TimeSeries(
        timeSeries.widgetId,
        timeSeries.username,
        timeSeries.type,
        timeSeries.name,
        timeSeries.description,
        timeSeries.width || 30,
        timeSeries.height || 5,
        timeSeries.x || 0,
        timeSeries.y || 0,
        timeSeries.options || {
          orientation: ChartOrientation.VERTICAL,
          mode: BarMode.STACK,
        },
        timeSeries.dataSource || [],
        timeSeries.dataValues,
        timeSeries.dataType,
        timeSeries.chartType || ChartType.BAR,
        timeSeries.interval || DateInterval.DAY,
        timeSeries.ecosystemId,
      );
    } else if (widget.type === AnalysisTypes.TIMELINE) {
      const timeline = widget as any;
      const extras = new Query();
      extras.query = extras.dtoToModel(timeline.query);
      extras.type = JSON.parse(timeline._extras).type;
      return new Timeline(
        timeline.widgetId,
        timeline.username,
        timeline.type,
        timeline.name,
        timeline.description,
        timeline.chartType,
        timeline.ecosystemId,
        timeline.width,
        timeline.height,
        timeline.x,
        timeline.y,
        timeline.tagId,
        timeline.createdDate,
        timeline.sharedTag,
        extras,
        timeline.categoryId,
        timeline.lastTaggingTime,
        timeline.updatedDate,
        timeline.alertTypeId,
      );
    }
  }
  static createObjectByType(
    type: AnalysisTypes,
    ecosystemId: string,
  ): TagAnalysis | TagComparison | TimeSeries | Timeline {
    if (type === AnalysisTypes.TAG_ANALYSIS) {
      return new TagAnalysis(
        undefined,
        undefined,
        AnalysisTypes.TAG_ANALYSIS,
        undefined,
        '',
        30,
        5,
        0,
        0,
        {
          orientation: ChartOrientation.VERTICAL,
        },
        [],
        [],
        10,
        ChartType.BAR,
        ecosystemId,
      );
    } else if (type === AnalysisTypes.TAG_COMPARISON) {
      return new TagComparison(
        undefined,
        undefined,
        AnalysisTypes.TAG_COMPARISON,
        undefined,
        undefined,
        30,
        5,
        0,
        0,
        {
          orientation: ChartOrientation.VERTICAL,
        },
        [],
        [],
        undefined,
        ChartType.BAR,
        ecosystemId,
      );
    } else if (type === AnalysisTypes.TIME_SERIES) {
      return new TimeSeries(
        undefined,
        undefined,
        AnalysisTypes.TIME_SERIES,
        undefined,
        undefined,
        30,
        5,
        0,
        0,
        {
          orientation: ChartOrientation.VERTICAL,
          mode: BarMode.STACK,
        },
        [],
        [],
        TagCategory['My Tags'],
        ChartType.BAR,
        DateInterval.DAY,
        ecosystemId,
      );
    } else if (type === AnalysisTypes.TIMELINE) {
      return new Timeline(
        undefined,
        undefined,
        AnalysisTypes.TIMELINE,
        undefined,
        undefined,
        ChartType.TIMELINE,
        ecosystemId,
        25,
        8,
        0,
        0,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
      );
    }
  }
}
