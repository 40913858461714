import {
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { PillType } from './pill.enum';
import { IPill } from './pill.interface';
import { Sizes } from '../../enums/shared.enum';

@Component({
  selector: 'itq-pill',
  templateUrl: './pill.component.html',
  styleUrls: ['./pill.component.scss'],
})
export class PillComponent implements OnInit {
  @Input() class: string;
  @Input() size = Sizes['X-SM'];
  @Input() item: IPill;
  @Input() padding = Sizes['X-SM'];
  @Input() type = PillType.BUTTON;
  @Input() stopPropagation = false;
  @Input() disabled = false;
  @Input() width: number;

  @Output() select = new EventEmitter<void>();

  readonly PillType = PillType;
  readonly Sizes = Sizes;

  @HostBinding('style.width') styleWidth: string;

  constructor() {}

  ngOnInit(): void {
    if (this.width) {
      this.styleWidth = this.width + 'px';
    }
  }

  onClick(event: MouseEvent): void {
    if (this.stopPropagation) {
      event.stopPropagation();
    }
    this.select.emit();
  }
}
