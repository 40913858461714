import { DatePipe } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import {
  ApiConfigService,
  ApiRequestService,
  DTOCreation,
  DTOTypeConverter,
} from '@intorqa-ui/api';
import { Subject } from 'rxjs';
import { DocumentItem, IMessage } from '../interfaces/document.interface';

@Injectable({
  providedIn: 'root',
})
export class DocumentItemService {
  public shareEmailObservable = new Subject<DocumentItem>();

  constructor(
    private apiRequestService: ApiRequestService,
    @Inject(ApiConfigService) private config,
    private datePipe: DatePipe,
  ) {}

  getDocument(documentId: string): Promise<DocumentItem> {
    return this.apiRequestService.get(
      '/document/' + encodeURIComponent(documentId),
      {},
      new DTOTypeConverter<DocumentItem>(),
    );
  }

  getParentDocument(document: DocumentItem): Promise<DocumentItem> {
    return this.apiRequestService.get(
      `/document/parent/${document.emitReplyToId}?source=${document.emitSource}`,
      {},
      new DTOTypeConverter<DocumentItem>(),
    );
  }

  async detectLanguage(content: string): Promise<Response> {
    const form = new FormData();
    form.append('q', content);
    form.append('format', 'text');
    const requestOptions: RequestInit = {
      method: 'POST',
      body: form,
    };
    return await fetch(
      this.config.gTranslateApi.detectLanguage +
        this.config.gTranslateApi.token,
      requestOptions,
    );
  }

  async translate(content: string, source: string): Promise<Response> {
    const form = new FormData();
    form.append('source', source);
    form.append('q', content);
    form.append('target', 'en');
    form.append('format', 'text');
    const requestOptions: RequestInit = {
      method: 'POST',
      body: form,
    };
    return await fetch(
      this.config.gTranslateApi.translateUrl + this.config.gTranslateApi.token,
      requestOptions,
    );
  }

  public getRepliesCount(document: DocumentItem): Promise<number> {
    return new Promise((resolve) => {
      this.apiRequestService
        .get(
          `/document/${document.id}/replies/count?source=${document.emitSource}`,
          {},
          new DTOTypeConverter<number>(),
          undefined,
          'v1.0',
        )
        .then((response: number) => {
          resolve(response);
        });
    });
  }

  async postSlackMessage(result: any, item: DocumentItem): Promise<any> {
    const blocks = [
      {
        color: '#1de62a',
        blocks: [
          {
            type: 'divider',
          },
          {
            type: 'section',
            text: {
              type: 'mrkdwn',
              text: `*<https://${window.location.host}/document/${
                item.id
              }|${JSON.stringify(item.emitHeadline).replace(
                /\\n/g,
                '',
              )}>*\n*${this.datePipe.transform(
                item.emitDocumentDate?.toString(),
                'short',
              )}${
                item.emitActor
                  ? ` by ${item.emitActor.replace(/\\n/g, '')}`
                  : ''
              }*\n*${item.emitSource.replace(
                /\\n/g,
                '',
              )} - ${item.emitChannel.replace(/\\n/g, '')}*\n\n${
                item.emitBodyText?.length > 300
                  ? item.emitBodyText?.substring(0, 300) + '...'
                  : item.emitBodyText || ''
              }`,
            },
            accessory: {
              type: 'image',
              image_url: `https://${window.location.host}/assets/img/logo-spiral.png`,
              alt_text: 'Intorqa Logo',
            },
          },
          {
            type: 'divider',
          },
        ],
      },
    ];

    const form = new FormData();
    form.append('token', result.authed_user.access_token);
    form.append('channel', result.incoming_webhook.channel_id);
    form.append('attachments', JSON.stringify(blocks));
    const requestOptions: RequestInit = {
      method: 'POST',
      body: form,
    };
    return await fetch(
      'https://slack.com/api/chat.postMessage',
      requestOptions,
    );
  }

  async getSlackToken(code: string): Promise<Response> {
    const form = new FormData();
    form.append('client_id', this.config.slackConfig.clientId);
    form.append('client_secret', this.config.slackConfig.clientSecret);
    form.append('code', code);
    const requestOptions: RequestInit = {
      method: 'POST',
      body: form,
    };
    return await fetch('https://slack.com/api/oauth.v2.access', requestOptions);
  }
}
