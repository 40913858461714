import { TemplateRef } from '@angular/core';
import { IsTagEnabledPipe, TableColumn } from '@intorqa-ui/core';
import { cloneDeep } from 'lodash';
import { AnalysisTypes, ChartType } from '../enums/widget.enum';
import { IDisplayType, ITimeline } from '../interfaces/widget.interface';
import { Widget } from '@portal/boards/models/widget';
import { ChartFactory } from '@portal/boards/factories/chart';
import { TIMELINE_CHART_TYPES } from '@portal/boards/const/timeline.const';

export class Timeline extends Widget implements ITimeline {
  constructor(
    public widgetId: string,
    public username: string,
    public type: AnalysisTypes,
    public name: string,
    public description: string,
    public chartType: ChartType,
    public ecosystemId: string,
    public width: number,
    public height: number,
    public x: number,
    public y: number,
    public tagId: string,
    public createdDate: number,
    public sharedTag: boolean,
    public _extras: any,
    public categoryId: string,
    public lastTaggingTime: number,
    public updatedDate: number,
    public alertTypeId: string,
  ) {
    super(widgetId, username, type, name, description, chartType, ecosystemId);
  }

  public hasOptions(): boolean {
    return this.tagId ? true : false;
  }

  public hasMetrics(): boolean {
    return this.tagId ? true : false;
  }

  public getTableColumns(template: TemplateRef<unknown>): Array<TableColumn> {
    return [
      {
        name: undefined,
        dataKey: 'result',
        isSortable: true,
        customRender: true,
        template,
      },
    ];
  }

  public getDisplayTypes(): Array<IDisplayType> {
    return TIMELINE_CHART_TYPES;
  }

  public getSelectedType(): IDisplayType {
    if (TIMELINE_CHART_TYPES?.length > 0) {
      const widget = cloneDeep(this);
      widget.chartType = this.chartType;
      const chart = ChartFactory.createObject(widget);
      return chart.getSelectedType(TIMELINE_CHART_TYPES, this);
    }
  }

  public getRuleValue(): Array<string> {
    return [this.tagId || this.widgetId];
  }

  public isEnabled(isTagEnabledPipe: IsTagEnabledPipe): boolean {
    return isTagEnabledPipe.transform(this);
  }

  public resetMetrics(): void {}
}
