import { NoteTypes } from "../enums/profile.enum";
import { IAliasNote } from "../interfaces/alias.interface";
import { IBioLinkNote } from "../interfaces/bio-link.interface.ts";
import { ICodeSnippetNote } from "../interfaces/code-snippet.interface";
import { IContactDetailsNote } from "../interfaces/contact-details.interface";
import { IDomainDetailsNote } from "../interfaces/domain-details.interface";
import { IFileHashNote } from "../interfaces/file-hash.interface";
import { IGamerTagNote } from "../interfaces/gamer-tag.interface";
import { IPaymentDetailsNote } from "../interfaces/payment-details-note.interface";
import { IProfileLinkNote } from "../interfaces/profile-link.interface";
import { IProfileNote } from "../interfaces/profile-note.interface";
import { IRepoLinkNote } from "../interfaces/repo-link-note.interface";
import { IScreenshotNote } from "../interfaces/screenshot.interface";
import { ISocialChannelNote } from "../interfaces/social-channel-note.interface";
import { IStoreFrontNote } from "../interfaces/store-front.interface";
import { ITextNote } from "../interfaces/text-note.interface";
import { IVideoLinkNote } from "../interfaces/video-link.interface";
import { AliasNote } from "../models/alias-note";
import { BioLinkNote } from "../models/bio-link-note";
import { CodeSnippetNote } from "../models/code-snippet-note";
import { ContactDetailsNote } from "../models/contact-details-note";
import { DomainDetailsNote } from "../models/domain-details-note";
import { FileHashNote } from "../models/file-hash";
import { GamerTagNote } from "../models/gamer-tag-note";
import { PaymentDetailsNote } from "../models/payment-details-note";
import { ProfileLinkNote } from "../models/profile-link-note";
import { RepoLinkNote } from "../models/repo-link";
import { ScreenshotNote } from "../models/screenshot";
import { SocialChannelNote } from "../models/social-channel-note";
import { StoreFrontNote } from "../models/store-front-note";
import { TextNote } from "../models/text-note";
import { VideoLinkNote } from "../models/video-link";

export class NotesFactory {
  static generateNote(
    noteType: NoteTypes,
    item: IProfileNote
  ): SocialChannelNote | TextNote | PaymentDetailsNote {
    if (noteType === NoteTypes.SocialChannels) {
      const socialChannelNote = item as unknown as ISocialChannelNote;
      return new SocialChannelNote(
        item.id,
        item.profileId,
        item.typeId,
        item.documentId,
        item.updatedDate,
        item.updatedBy,
        socialChannelNote.subType,
        socialChannelNote.rawValue,
        socialChannelNote.textNote,
        socialChannelNote.rawTextNote
      );
    } else if (noteType === NoteTypes.PaymentDetails) {
      const paymentDetailsNote = item as unknown as IPaymentDetailsNote;
      return new PaymentDetailsNote(
        item.id,
        item.profileId,
        item.typeId,
        item.documentId,
        item.updatedDate,
        item.updatedBy,
        paymentDetailsNote.subType,
        paymentDetailsNote.rawValue,
        paymentDetailsNote.textNote,
        paymentDetailsNote.rawTextNote
      );
    } else if (noteType === NoteTypes.Gamertag) {
      const gamerTagNote = item as unknown as IGamerTagNote;
      return new GamerTagNote(
        item.id,
        item.profileId,
        item.typeId,
        item.documentId,
        item.updatedDate,
        item.updatedBy,
        gamerTagNote.subType,
        gamerTagNote.rawValue,
        gamerTagNote.textNote,
        gamerTagNote.rawTextNote
      );
    } else if (noteType === NoteTypes.ProfileLink) {
      const profileLinkNote = item as unknown as IProfileLinkNote;
      return new ProfileLinkNote(
        item.id,
        item.profileId,
        item.typeId,
        item.documentId,
        item.updatedDate,
        item.updatedBy,
        profileLinkNote.subType,
        profileLinkNote.rawValue,
        profileLinkNote.textNote,
        profileLinkNote.rawTextNote
      );
    } else if (noteType === NoteTypes.Storefront) {
      const storeFrontNote = item as unknown as IStoreFrontNote;
      return new StoreFrontNote(
        item.id,
        item.profileId,
        item.typeId,
        item.documentId,
        item.updatedDate,
        item.updatedBy,
        storeFrontNote.subType,
        storeFrontNote.rawValue,
        storeFrontNote.textNote,
        storeFrontNote.rawTextNote
      );
    } else if (noteType === NoteTypes.Alias) {
      const aliasNote = item as unknown as IAliasNote;
      return new AliasNote(
        item.id,
        item.profileId,
        item.typeId,
        item.documentId,
        item.updatedDate,
        item.updatedBy,
        aliasNote.subType,
        aliasNote.rawValue,
        aliasNote.textNote,
        aliasNote.rawTextNote
      );
    } else if (noteType === NoteTypes.CodeRepoLink) {
      const repoLinkNote = item as unknown as IRepoLinkNote;
      return new RepoLinkNote(
        item.id,
        item.profileId,
        item.typeId,
        item.documentId,
        item.updatedDate,
        item.updatedBy,
        repoLinkNote.subType,
        repoLinkNote.rawValue,
        repoLinkNote.textNote,
        repoLinkNote.rawTextNote
      );
    } else if (noteType === NoteTypes.ContactDetails) {
      const contactDetailsNote = item as unknown as IContactDetailsNote;
      return new ContactDetailsNote(
        item.id,
        item.profileId,
        item.typeId,
        item.documentId,
        item.updatedDate,
        item.updatedBy,
        contactDetailsNote.subType,
        contactDetailsNote.rawValue,
        contactDetailsNote.textNote,
        contactDetailsNote.rawTextNote
      );
    } else if (noteType === NoteTypes.DomainDetails) {
      const domainDetailsNote = item as unknown as IDomainDetailsNote;
      return new DomainDetailsNote(
        item.id,
        item.profileId,
        item.typeId,
        item.documentId,
        item.updatedDate,
        item.updatedBy,
        domainDetailsNote.subType,
        domainDetailsNote.rawValue,
        domainDetailsNote.textNote,
        domainDetailsNote.rawTextNote
      );
    } else if (noteType === NoteTypes.BioLink) {
      const bioLinkNote = item as unknown as IBioLinkNote;
      return new BioLinkNote(
        item.id,
        item.profileId,
        item.typeId,
        item.documentId,
        item.updatedDate,
        item.updatedBy,
        bioLinkNote.subType,
        bioLinkNote.rawValue,
        bioLinkNote.textNote,
        bioLinkNote.rawTextNote
      );
    } else if (noteType === NoteTypes.CodeSnippet) {
      const codeSnippetNote = item as unknown as ICodeSnippetNote;
      return new CodeSnippetNote(
        item.id,
        item.profileId,
        item.typeId,
        item.documentId,
        item.updatedDate,
        item.updatedBy,
        codeSnippetNote.subType,
        codeSnippetNote.value,
        codeSnippetNote.rawValue,
        codeSnippetNote.textNote,
        codeSnippetNote.rawTextNote
      );
    } else if (noteType === NoteTypes.FileHash) {
      const fileHashNote = item as unknown as IFileHashNote;
      return new FileHashNote(
        item.id,
        item.profileId,
        item.typeId,
        item.documentId,
        item.updatedDate,
        item.updatedBy,
        fileHashNote.rawValue,
        fileHashNote.textNote,
        fileHashNote.rawTextNote
      );
    } else if (noteType === NoteTypes.Screenshot) {
      const screenshotNote = item as unknown as IScreenshotNote;
      return new ScreenshotNote(
        item.id,
        item.profileId,
        item.typeId,
        item.documentId,
        item.updatedDate,
        item.updatedBy,
        screenshotNote.value,
        screenshotNote.rawValue,
        screenshotNote.textNote,
        screenshotNote.rawTextNote
      );
    } else if (noteType === NoteTypes.VideoLink) {
      const videoLinkNote = item as unknown as IVideoLinkNote;
      return new VideoLinkNote(
        item.id,
        item.profileId,
        item.typeId,
        item.documentId,
        item.updatedDate,
        item.updatedBy,
        videoLinkNote.subType,
        videoLinkNote.value,
        videoLinkNote.rawValue,
        videoLinkNote.textNote,
        videoLinkNote.rawTextNote
      );
    } else {
      const textNote = item as unknown as ITextNote;
      return new TextNote(
        item.id,
        item.profileId,
        item.typeId,
        item.documentId,
        item.updatedDate,
        item.updatedBy,
        textNote.textNote,
        textNote.rawTextNote
      );
    }
  }
}
