import { Pipe, PipeTransform } from "@angular/core";
import { DTOQueryFieldType } from "projects/core/src/lib/enums/query.enum";
import { Query } from "@portal/shared/models/query-model";
import { DiscordNavigationItem } from "@portal/widget-settings/models/discord-navigation-item.model";
import { NavigationHistoryItem } from "@portal/widget-settings/models/navigation-history-item.model";

@Pipe({
  name: "getSearchTerm",
})
export class GetSearchTermPipe implements PipeTransform {
  constructor() {}

  transform(query: Query): string | undefined {
    const searchTerm = query.getRuleValueByField(DTOQueryFieldType.content);
    return searchTerm && searchTerm.value?.length > 0
      ? searchTerm?.value[0]
      : undefined;
  }
}

@Pipe({
  name: "isDiscordNavigation",
})
export class IsDiscordNavigationPipe implements PipeTransform {
  constructor() {}

  transform(navigationItem: NavigationHistoryItem): boolean {
    return navigationItem instanceof DiscordNavigationItem;
  }
}
