import { Component, Inject, OnInit } from "@angular/core";
import { FormGroup } from "@angular/forms";
import {
  CUSTOM_OVERLAY_DATA,
  CustomOverlayConfig,
  CustomOverlayRef,
  Utils,
} from "@intorqa-ui/core";
import { ProfileNote } from "@portal/profiles/models/profile-note";
import { ConnectionsService } from "@portal/profiles/services/connections.service";
import { WidgetActions } from "@portal/shared/enums/widget.enum";
import { ISegment } from "@portal/widget-settings/interfaces/widget-settings.interface";
import { NavigationHistoryItem } from "@portal/widget-settings/models/navigation-history-item.model";

@Component({
  selector: "itq-post-notes-wizard",
  templateUrl: "./post-notes-wizard.component.html",
  styleUrls: ["./post-notes-wizard.component.scss"],
})
export class PostNotesWizardComponent implements OnInit {
  public form: FormGroup;
  public navigationItem: NavigationHistoryItem;
  public note: ProfileNote;
  public segment: ISegment;

  readonly WidgetActions = WidgetActions;

  constructor(
    @Inject(CUSTOM_OVERLAY_DATA) public config: CustomOverlayConfig,
    readonly connectionsService: ConnectionsService,
    readonly customOverlayRef: CustomOverlayRef
  ) {
    this.form = new FormGroup({});
    this.note = new ProfileNote(
      Utils.generateUUID(),
      this.config?.data?.profile?.profileId,
      undefined,
      this.config?.data?.segment?.value?.id,
      undefined,
      undefined
    );
  }

  ngOnInit() {
    this.navigationItem = new NavigationHistoryItem(
      "postItem",
      undefined,
      WidgetActions.EXPLORE,
      undefined,
      undefined,
      this.form,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined
    );
  }
}
