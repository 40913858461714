@if (showHeader) {
  <div class="px-2 py-1" (mousedown)="$event.stopImmediatePropagation()">
    <itq-input
      class="w-full"
      #inputControl
      [(ngModel)]="query"
      [placeholder]="'Search...'"
      (search)="onDataBound()"
      (clear)="(true)"
    ></itq-input>
  </div>
}

<div class="dropdown-container">
  <ul
    *ngIf="dataSource?.length > 0; else noResults"
    (scroll)="onScroll()"
    #listSearch
  >
    <ng-container *ngFor="let item of dataSource">
      <itq-dropdown-item
        [ngClass]="selection | setActiveItemClass: item[this.dataFields.value]"
        [item]="item"
        [dataFields]="dataFields"
        [httpBinding]="httpBinding"
        (changeValue)="onChangeValue($event)"
      ></itq-dropdown-item>
    </ng-container>
  </ul>
</div>
@if(showLoader) {
    <itq-loader class="p-4"></itq-loader>

}
<ng-template #noResults>
  @if (dataSource?.length === 0) {
    <span
      class="font-semibold italic text-sm text-gray-700 m-2 flex items-center"
    >
      <fa-icon [icon]="['far', 'info-circle']"></fa-icon>
      {{ emptyDataSourceMessage }}
    </span>
  } @else {
    <itq-loader class="p-4"></itq-loader>
  }
</ng-template>
