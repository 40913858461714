import { EChartsOption } from 'echarts';
import { ChartType } from '../../shared/enums/widget.enum';
import {
  IDataPoint,
  IDisplayType,
  ISerie,
} from '../../shared/interfaces/widget.interface';
import { Widget } from './widget';

export abstract class Chart {
  constructor(public name: ChartType) {}

  public getDataPoint = (
    item: IDataPoint,
    segment: any,
    tagId: string,
    seriesIndex: number,
  ) => {
    let dataPoint = {
      name: item?.category,
      value: item?.count,
      tagId: item?.tagId || tagId,
      unformattedName: item?.value,
    };
    if (
      item?.category === segment?.name &&
      segment.seriesIndex === seriesIndex
    ) {
      dataPoint = {
        ...dataPoint,
        ...{
          itemStyle: { color: '#1de62a' },
        },
      };
    }
    return dataPoint;
  };

  public abstract getOptions(
    data: Array<ISerie>,
    segment: any,
    settings: { [key: string]: any },
  ): EChartsOption;

  public abstract getSelectedType(
    displayTypes: Array<IDisplayType>,
    widget: Widget,
  ): IDisplayType;
}
