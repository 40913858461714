import eventbus from '@vertx/eventbus-bridge-client.js';
import { Inject, Injectable } from '@angular/core';
import { ApiConfigService } from '@intorqa-ui/api';

@Injectable({
  providedIn: 'root',
})
export class EventBusService {
  public eventBus: Promise<eventbus.EventBus>;
  public registeredEvents: string[] = [];
  public onOpen: Promise<void>;

  constructor(@Inject(ApiConfigService) private config: any) {
    const connection = this.connect();
    this.eventBus = connection.then((conn) => conn.eventBus);
    this.onOpen = connection.then((conn) => conn.onOpen);
  }

  async connect(): Promise<{
    eventBus: eventbus.EventBus;
    onOpen: Promise<void>;
  }> {
    console.log('Connecting to eventbus');

    const options = {
      vertxbus_reconnect_attempts_max: Infinity,
      vertxbus_reconnect_delay_min: 1000,
      vertxbus_reconnect_delay_max: 5000,
      vertxbus_reconnect_exponent: 2,
      vertxbus_randomization_factor: 0.5,
    };
    const eb = new eventbus(`${this.config.evBusUrl}`, options);
    eb.enableReconnect(true);
    const onOpen = new Promise<void>((resolve) => {
      eb.onopen = () => {
        console.log('Event bus connected...');
        resolve();
      };
    });
    eb.onerror = () => console.log('Event bus error');
    eb.onclose = () => console.log('Event bus disconnected');

    return { eventBus: eb, onOpen };
  }

  async send(address: string, message: any): Promise<void> {
    this.eventBus.then((eb: eventbus.EventBus) => {
      eb.send(address, message);
    });
  }

  async registerEvent(address: string, handler: any): Promise<void> {
    if (this.registeredEvents.includes(address)) {
      console.log(`Event ${address} is already registered`);
      return;
    }
    await this.onOpen;
    this.eventBus.then((eb: any) => {
      console.log(address + ' registered');
      if (eb.state === 1) {
        eb.registerHandler(address, handler);
        this.registeredEvents.push(address);
      }
    });
  }

  unRegisterEvent(address: string, handler: any): void {
    this.eventBus.then((eb: eventbus.EventBus) => {
      eb?.unregisterHandler(address, handler);
      this.registeredEvents = this.registeredEvents.filter(
        (event) => event !== address,
      );
      console.log(address + ' unregistered');
    });
  }
}
