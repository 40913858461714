<div class="flex items-center space-x-1">
  @for (item of dataSource; track item.id) {
    <itq-mat-button
      class="mr-1"
      [materialStyle]="item.id === value ? 'flat' : 'basic'"
      [style]="item.id === value ? 'primary' : undefined"
      [type]="'button'"
      [align]="direction === Directions.VERTICAL ? Align.START : Align.CENTER"
      [disabled]="item.disabled"
      (clickEvent)="onClick(item)"
    >
      @if (item.template) {
        <ng-container *ngTemplateOutlet="item.template"></ng-container>
      } @else {
        @if (item.icon) {
          <fa-icon [icon]="item.icon" [size]="'sm'"></fa-icon>
        } @else {
          <itq-svg class="w-5" [icon]="item.svgIcon"></itq-svg>
        }
        <span>
          {{ item.tooltip }}
        </span>
      }
    </itq-mat-button>
  }
</div>
