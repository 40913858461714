import { Injectable } from '@angular/core';
import { ApiRequestService, DTOTypeConverter } from '@intorqa-ui/api';
import { QueryFilters } from '@intorqa-ui/core';
import { WidgetActions } from '@portal/shared/enums/widget.enum';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { NotesFactory } from '../factories/notes.factory';
import { INoteType } from '../interfaces/note-type.interface';
import {
  INotesData,
  IProfileNote,
  IProfileNotesResults,
} from '../interfaces/profile-note.interface';
import { NoteType } from '../models/note-type';
import { Profile } from '../models/profile';
import { ProfileNote } from '../models/profile-note';

@Injectable({
  providedIn: 'root',
})
export class NotesService {
  public reset$ = new Subject<void>();
  public noteTypes$ = new Subject<Array<NoteType>>();
  public notes$ = new Subject<INotesData>();
  public showAddNotes$ = new Subject<WidgetActions | void>();
  public showViewNotes$ = new Subject<boolean>();

  private _notes: INotesData = {
    items: [],
    totalCount: 0,
  };
  private _noteTypes: Array<NoteType> = [];

  public get noteTypes(): Array<NoteType> {
    return this._noteTypes;
  }

  public set noteTypes(v: Array<NoteType>) {
    this._noteTypes = v;
  }

  public get notes(): INotesData {
    return this._notes;
  }

  public set notes(v: INotesData) {
    this._notes = v;
  }

  constructor(private apiRequestService: ApiRequestService) {}

  public addNotes(
    profile: Profile,
    targetProfile: Profile,
    notes: Array<ProfileNote>,
  ): Observable<INotesData> {
    notes.map((item: ProfileNote) => {
      delete item.id;
    });

    return this.apiRequestService
      .postToObservable(
        `/profiles/${targetProfile.profileId}/notes`,
        new DTOTypeConverter<Array<IProfileNote>>(),
        notes,
      )
      .pipe(
        map((response: Array<IProfileNote>) => {
          if (targetProfile.profileId === profile.profileId) {
            response?.forEach((item: any) => {
              const type = this.getTypeById(item.typeId);
              this.notes.items.push(
                NotesFactory.generateNote(type.name, {
                  id: item.id,
                  profileId: item.profileId,
                  typeId: item.typeId,
                  updatedDate: item.updatedDate,
                  updatedBy: item.updatedBy,
                  subType: item.subType,
                  rawValue: item.rawValue,
                  value: item.value,
                  textNote: item.textNote,
                  rawTextNote: item.rawTextNote,
                } as IProfileNote),
              );
              this.notes.totalCount += notes?.length;
            });
          }
          return this.notes;
        }),
      );
  }

  public removeNotes(
    profileId: string,
    notesIds: Array<string>,
  ): Observable<INotesData> {
    this.notes.items = this.notes?.items?.filter(
      (item: ProfileNote) => !notesIds.includes(item.id),
    );
    return this.apiRequestService
      .deleteToObserable(`/profiles/${profileId}/notes`, notesIds)
      .pipe(
        map(() => {
          this.notes.items = this.notes.items.filter(
            (item: ProfileNote) => !notesIds.includes(item.id),
          );
          this.notes.totalCount -= notesIds?.length;
          this.notes$.next(this.notes);
          return this.notes;
        }),
      );
  }

  public getNotes(
    id: string,
    params: QueryFilters,
    showLoader = true,
  ): Observable<INotesData> {
    let pageQuery = `page=${params.page}`;
    pageQuery += `&size=${params.pageSize}`;
    pageQuery += `&sortField=${params.sort.active}`;
    pageQuery += `&sortOrder=${params.sort.direction}`;
    return this.apiRequestService
      .postToObservable(
        `/profiles/${id}/notes/list?${pageQuery}`,
        new DTOTypeConverter<IProfileNotesResults>(),
        params?.query,
      )
      .pipe(
        map((response: IProfileNotesResults) => {
          this.notes = {
            items: response?.items?.map((item: any) => {
              const typeName = this.getTypeById(item.typeId)?.name;
              return NotesFactory.generateNote(typeName, {
                id: item.id,
                profileId: item.profileId,
                typeId: item.typeId,
                updatedDate: item.updatedDate,
                documentId: item.documentId,
                updatedBy: item.updatedBy,
                subType: item.subType,
                rawValue: item.rawValue,
                value: item.value,
                textNote: item.textNote,
                rawTextNote: item.rawTextNote,
              } as IProfileNote);
            }),
            totalCount: response?.totalCount,
          };

          this.notes$.next(this.notes);
          return this.notes;
        }),
      );
  }

  public getNoteTypes(profileTypeId: string): Observable<Array<NoteType>> {
    return this.apiRequestService
      .getToObservable(
        `/profiles/notes/types?profileTypeId=${profileTypeId}`,
        new DTOTypeConverter<Array<INoteType>>(),
      )
      .pipe(
        map((response: Array<INoteType>) => {
          this.noteTypes = response?.map(
            (item: INoteType) =>
              new NoteType(item.id, item.name, item.subTypes),
          );
          this.noteTypes$.next(this.noteTypes);
          return this.noteTypes;
        }),
      );
  }

  public resetNotes(): void {
    this.notes = {
      items: [],
      totalCount: 0,
    };
  }

  public getTypeById(id: string): NoteType {
    return this.noteTypes.find((item: NoteType) => item.id === id);
  }

  public getNoteById(id: string): ProfileNote {
    return this.notes?.items?.find((item: ProfileNote) => item.id === id);
  }

  public getUsers(profileId: string): Observable<Array<string>> {
    return this.apiRequestService.getToObservable(
      `/profiles/${profileId}/notes/users`,
      new DTOTypeConverter<Array<string>>(),
      undefined,
    );
  }

  public updateNote(
    profileId: string,
    note: ProfileNote,
  ): Observable<INotesData> {
    const anyNote = note as any;
    const updateNoteDTO = {
      value: anyNote?.value,
      typeId: anyNote?.typeId,
      rawValue: anyNote?.rawValue,
      subType: anyNote?.subType,
      textNote: anyNote?.textNote,
      rawTextNote: anyNote?.rawTextNote,
      documentId: anyNote?.documentId,
    };
    return this.apiRequestService
      .putToObservable(`/profiles/${profileId}/notes/${note.id}`, updateNoteDTO)
      .pipe(
        map((response: IProfileNote) => {
          const responseNote = response as any;
          this.notes.items = this.notes.items?.map((item: any) => {
            if (response.id === item.id) {
              const typeName = this.getTypeById(item.typeId)?.name;
              return NotesFactory.generateNote(typeName, {
                id: responseNote.id,
                profileId: responseNote.profileId,
                typeId: responseNote.typeId,
                updatedDate: responseNote.updatedDate,
                documentId: responseNote.documentId,
                updatedBy: responseNote.updatedBy,
                subType: responseNote.subType,
                rawValue: responseNote.rawValue,
                value: responseNote.value,
                textNote: responseNote.textNote,
                rawTextNote: responseNote.rawTextNote,
              } as IProfileNote);
            } else {
              return item;
            }
          });
          this.notes$.next(this.notes);
          return this.notes;
        }),
      );
  }
}
