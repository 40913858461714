import { Injectable } from '@angular/core';
import { Moment } from 'moment';
import { Subject } from 'rxjs';
import { DateQueryType } from '../enums/date-range.enum';
import { IPreset } from '../interfaces/date-range.interface';
import { DateRange } from '../models/date-range';

@Injectable({
  providedIn: 'root',
})
export class DateRangeService {
  public changeDates$: Subject<IPreset> = new Subject<IPreset>();
  public preset: IPreset;

  constructor() {}

  getPresetLabel(startDate: Moment, endDate: Moment): DateQueryType {
    const presets = DateRange.getDatePresets();
    if (this.preset) {
      const selectedPreset = presets.find(
        (preset: IPreset) =>
          preset?.start?.isSame(startDate, 'day') &&
          preset?.end?.isSame(endDate, 'day'),
      );
      if (selectedPreset) {
        return selectedPreset.label;
      }
    }
    return DateQueryType.Custom;
  }
}
