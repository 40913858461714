<span class="font-normal" *ngIf="label">{{ label }}:</span>
<ng-container *ngFor="let element of dataSource; let i = index">
  <ng-container *ngIf="!maxSelections || i < maxSelections">
    <itq-pill
      [disabled]="disabled"
      [class]="'secondary rounded-md'"
      [item]="element"
      [type]="PillType.CUSTOM"
      [stopPropagation]="true"
      (select)="onClick(element)"
    >
      <span>
        {{ element?.name }}
      </span>
      <fa-icon [icon]="['far', 'circle-xmark']" [size]="'sm'"></fa-icon>
    </itq-pill>
  </ng-container>
</ng-container>
<ng-container *ngIf="maxSelections && dataSource?.length - 1 >= maxSelections">
  <fa-icon
    class="'more__icon"
    [icon]="['far', 'circle-ellipsis']"
    [size]="'lg'"
    (mouseover)="onToggle($event, true)"
    (mouseout)="onToggle($event, false)"
  ></fa-icon>
</ng-container>
<ng-template #portalContent> </ng-template>
