import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from '@angular/material/core';
import { MatDateRangePicker } from '@angular/material/datepicker';
import moment, { Moment } from 'moment';
import { Sizes } from '../../enums/shared.enum';
import { DateRangeService } from '../../services/date-range.service';
import { DateRangeHeaderComponent } from './components/date-range-header/date-range-header.component';
import { CustomErrorStateMacther } from './date-range-error-matcher';
import { IPreset } from '../../interfaces/date-range.interface';
import { IPresetQuery } from '../../interfaces/query-filters';
import { DateRange } from '../../models/date-range';
import { DateQueryType } from '../../enums/date-range.enum';
import { Subscription } from 'rxjs';

const MY_FORMATS = {
  parse: {
    dateInput: 'DD-MM-YYYY',
  },
  display: {
    dateInput: 'DD-MM-YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};
@Component({
  selector: 'itq-date-range',
  templateUrl: './date-range.component.html',
  providers: [
    DateRangeService,
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class DateRangeComponent implements OnInit {
  @Input()
  set dates(value: IPresetQuery) {
    if (value) {
      this._dates = {
        label: DateQueryType[value.label],
        start: moment(DateRange.convertFromEpochSeconds(value.start)),
        end: value.end
          ? moment(DateRange.convertFromEpochSeconds(value?.end))
          : undefined,
      };
      this.range.controls.start.setValue(this._dates.start);
      this.range.controls.end.setValue(this._dates.end || moment());
      this.dateRangeService.preset = this._dates;
    }
  }

  get dates(): IPreset {
    return this._dates;
  }

  @Input() disabled: boolean;
  @Input() class = 'w-64';

  public error: boolean;
  public customErrorStateMatcher = new CustomErrorStateMacther();
  public _dates: IPreset;

  @Output() search = new EventEmitter<IPresetQuery>();

  @ViewChild('picker') picker: MatDateRangePicker<any>;

  public range = new FormGroup({
    start: new FormControl(undefined),
    end: new FormControl(undefined),
  });
  private subscriptions = new Subscription();

  readonly DateRangeHeaderComponent = DateRangeHeaderComponent;
  readonly minDate = moment().subtract(3, 'year').toDate();
  readonly maxDate = new Date();
  readonly Sizes = Sizes;

  constructor(private dateRangeService: DateRangeService) {}

  ngOnInit(): void {
    this.bindChangeDatesSubscription();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  private bindChangeDatesSubscription(): void {
    this.subscriptions.add(
      this.dateRangeService.changeDates$.subscribe((response: IPreset) => {
        this.range.controls.start.setValue(response.start);
        this.range.controls.end.setValue(response.end);
      }),
    );
  }

  public onApply(): void {
    if (this.range.controls.start.value && this.range.controls.end.value) {
      this.onSearch();
    } else {
      this.onClearDate();
    }
  }

  public onClearDate(): void {
    this.dateRangeService.preset = undefined;
    this.range.controls.start.setValue(undefined);
    this.range.controls.end.setValue(undefined);
    this.onSearch();
  }

  private onSearch(): void {
    const preset = this.generatePreset();
    this.search.emit(preset);
  }

  private generatePreset(): IPresetQuery {
    const startDate: Moment = this.range.controls.start.value;
    if (startDate) {
      const endDate: Moment = this.range.controls.end.value;
      const label = DateRange.getPresetLabel(startDate, endDate);
      return {
        label: label ? DateQueryType[label] : undefined,
        start: DateRange.convertToEpochSec(startDate.toDate()),
        end: DateRange.convertToEpochSec(endDate.toDate()),
      };
    } else {
      return undefined;
    }
  }
}
