import { TableChart } from '../models/table-chart';
import { LineChart } from '../models/line-chart';
import {
  ChartOrientation,
  ChartType,
  LineMode,
  PieMode,
  WidgetOption,
} from '../../shared/enums/widget.enum';
import { Chart } from '../models/chart';
import { HorizontalBarChart } from '../models/horizontal-bar-chart';
import { VerticalBarChart } from '../models/vertical-bar-chart';
import { AreaChart } from '../models/area-chart';
import { PieChart } from '../models/pie-chart';
import { DoughnutChart } from '../models/doughnut-chart';
import { FoamtreeChart } from '../models/foamtree-chart';
import { NetworkChart } from '../models/network-chart';
import { TagComparison } from '../models/tag-comparison';
import { TimeSeries } from '../models/time-series';
import { TagAnalysis } from '../models/tag-analysis';

export class ChartFactory {
  static createObject(widget: TagAnalysis | TimeSeries | TagComparison): Chart {
    if (widget.chartType === ChartType.BAR) {
      const orientation = widget.getOption(WidgetOption.ORIENTATION);
      if (orientation === ChartOrientation.VERTICAL) {
        return new VerticalBarChart(widget.chartType);
      } else {
        return new HorizontalBarChart(widget.chartType);
      }
    } else if (widget.chartType === ChartType.LINE) {
      const mode = widget.getOption(WidgetOption.MODE);
      if (mode === LineMode.LINE) {
        return new LineChart(widget.chartType);
      } else {
        return new AreaChart(widget.chartType);
      }
    } else if (widget.chartType === ChartType.PIE) {
      const mode = widget.getOption(WidgetOption.MODE);
      if (mode === PieMode.PIE) {
        return new PieChart(widget.chartType);
      } else {
        return new DoughnutChart(widget.chartType);
      }
    } else if (widget.chartType === ChartType.TABLE) {
      return new TableChart(widget.chartType);
    } else if (widget.chartType === ChartType.FOAMTREE) {
      return new FoamtreeChart(widget.chartType);
    } else if (widget.chartType === ChartType.NETWORK) {
      return new NetworkChart(widget.name, widget.chartType);
    }
  }
}
