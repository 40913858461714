<itq-date-range-presets></itq-date-range-presets>

<div class="flex items-center p-2">
  <button
    class="p-0! flex items-center justify-center font-semibold"
    [ngStyle]="{ width: '40px', height: '40px' }"
    (click)="previousClicked('year', $event)"
    (mousedown)="$event.stopImmediatePropagation()"
  >
    <fa-icon [icon]="['far', 'chevrons-left']"></fa-icon>
  </button>
  <button
    class="p-0! flex items-center justify-center font-semibold"
    [ngStyle]="{ width: '40px', height: '40px' }"
    (click)="previousClicked('month', $event)"
  >
    <fa-icon [icon]="['far', 'chevrons-left']"></fa-icon>
  </button>
  <span class="flex-1 text-center font-semibold">{{ periodLabel }}</span>
  <button
    class="p-0! flex items-center justify-center font-semibold"
    [ngStyle]="{ width: '40px', height: '40px' }"
    (click)="nextClicked('month', $event)"
  >
    <fa-icon [icon]="['far', 'chevron-right']"></fa-icon>
  </button>
  <button
    class="p-0! flex items-center justify-center font-semibold"
    [ngStyle]="{ width: '40px', height: '40px' }"
    (click)="nextClicked('year', $event)"
  >
    <fa-icon [icon]="['far', 'chevrons-right']"></fa-icon>
  </button>
</div>
