<itq-form (ngSubmit)="form.valid && onSubmit()" [formGroup]="form">
  <div class="modal__body">
    <itq-headline-simple
      [iconName]="'envelope'"
      [iconSize]="'2x'"
      [iconType]="IconType.FONT_AWESOME"
      [title]="'Share by email'"
      [description]="
        'Please add recipient details and custom message to share your items'
      "
    ></itq-headline-simple>

    <itq-form-row class="fill input--name">
      <itq-form-element>
        <span class="font-semibold"> Email Address</span>
        <itq-input
          formControlName="email"
          [placeholder]="'Please enter your email address'"
        ></itq-input>
        <mat-error
          *ngIf="
            form.controls['email'].touched &&
            form.controls['email'].hasError('required')
          "
        >
          Email address is <strong>required</strong>
        </mat-error>
        <mat-error
          *ngIf="
            form.controls['email'].touched &&
            form.controls['email'].hasError('email')
          "
        >
          Email address is <strong>invalid</strong>
        </mat-error>
      </itq-form-element>
    </itq-form-row>
    <itq-form-row class="fill quill__editor quill__container">
      <span class="font-normal"> Message</span>
      <quill-editor
        #Editor
        [styles]="{ height: '200px' }"
        formControlName="content"
      ></quill-editor>
    </itq-form-row>
    <itq-form-row class="fill">
      <itq-form-element>
        <span class="font-semibold"> Document</span>
        <itq-document-item-tile
          *ngIf="document"
          [allowDrilldown]="false"
          [item]="document"
          [shareByEmail]="false"
        >
        </itq-document-item-tile>
      </itq-form-element>
    </itq-form-row>
  </div>
  <footer class="modal__footer">
    <itq-mat-button
      [type]="'submit'"
      [style]="'primary'"
      [materialStyle]="'flat'"
      [size]="Sizes['xx-lg']"
      [disabled]="!form.valid"
    >
      SEND
    </itq-mat-button>
  </footer>
</itq-form>
<itq-loader *ngIf="showLoader"></itq-loader>

<ng-template #documentItemTemplate let-row>
  <itq-document-item
    [data]="{
      item: row.item,
      mode: 'Tile'
    }"
    [showActions]="false"
  ></itq-document-item>
</ng-template>
