import { Component, OnInit } from '@angular/core';
import { DateAdapter } from '@angular/material/core';
import { MatDateRangePicker } from '@angular/material/datepicker';
import {
  Align,
  DateQueryLabel,
  DateQueryType,
  DateRange,
  DateRangeService,
  IPreset,
  Sizes,
} from '@intorqa-ui/core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'itq-date-range-presets',
  templateUrl: './date-range-presets.component.html',
  styleUrls: ['./date-range-presets.component.scss'],
})
export class DateRangePresetsComponent<D> implements OnInit {
  readonly customPresets: Array<IPreset>;
  readonly Sizes = Sizes;
  readonly Align = Align;

  public dateRange: IPreset;
  public subscription = new Subscription();

  readonly DateQueryLabel = DateQueryLabel;

  constructor(
    readonly dateRangeService: DateRangeService,
    private picker: MatDateRangePicker<D>,
    private dateAdapter: DateAdapter<D>,
  ) {
    this.customPresets = DateRange.getDatePresets();
    this.dateRange = this.dateRangeService.preset;
  }

  ngOnInit(): void {
    this.subscription.add(
      this.dateRangeService.changeDates$.subscribe((preset: IPreset) => {
        this.dateRange = preset;
      }),
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  selectRange(preset: IPreset): void {
    this.dateRange = preset;
    const start = this.dateAdapter.getValidDateOrNull(this.dateRange.start);
    const end = this.dateAdapter.getValidDateOrNull(this.dateRange.end);
    this.picker.select(start);
    this.picker.select(end);
    this.dateRangeService.changeDates$.next(preset);
    this.picker.close();
  }
}
