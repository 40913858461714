import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from '@angular/material/core';
import { MatDateRangePicker } from '@angular/material/datepicker';
import moment, { Moment } from 'moment';
import { Sizes } from '../../enums/shared.enum';
import { DateRangeService } from '../../services/date-range.service';
import { IPreset } from '../../interfaces/date-range.interface';
import { CustomErrorStateMacther } from '../date-range/date-range-error-matcher';
import { DateRangeHeaderComponent } from '../date-range/components/date-range-header/date-range-header.component';

const MY_FORMATS = {
  parse: {
    dateInput: 'DD-MM-YYYY',
  },
  display: {
    dateInput: 'DD-MM-YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};
@Component({
  selector: 'itq-toggle-date-range',
  templateUrl: './toggle-date-range.component.html',
  providers: [
    DateRangeService,
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class ToggleDateRangeComponent implements OnInit, OnChanges {
  @Input() dates: IPreset;
  @Input() isExpanded: boolean;
  @Input() disabled: boolean;
  @Input() class = 'w-64';

  public error: boolean;
  public customErrorStateMatcher = new CustomErrorStateMacther();
  public expanded: boolean;

  @Output() search = new EventEmitter<IPreset>();

  @ViewChild('picker') picker: MatDateRangePicker<any>;

  @HostListener('document:click', ['$event'])
  click(): void {
    if (this.range.controls.start.value || this.range.controls.end.value) {
      this.expanded = true;
    } else {
      this.expanded = this.isExpanded ? true : false;
    }
  }

  public range = new FormGroup({
    start: new FormControl(undefined),
    end: new FormControl(undefined),
  });

  readonly DateRangeHeaderComponent = DateRangeHeaderComponent;
  readonly minDate = moment().subtract(3, 'year').toDate();
  readonly maxDate = new Date();
  readonly Sizes = Sizes;

  constructor(private dateRangeService: DateRangeService) {}

  ngOnInit(): void {
    this.dateRangeService.preset = this.dates;
    this.expanded = this.dates || this.isExpanded ? true : false;
    if (this.dates?.start && this.dates?.end) {
      this.range.controls.start.setValue(moment(this.dates.start));
      this.range.controls.end.setValue(moment(this.dates.end));
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.dates?.previousValue !== changes?.dates?.currentValue) {
      if (!this.dates) {
        this.range.controls.start.setValue(undefined);
        this.range.controls.end.setValue(undefined);
      } else {
        if (
          changes?.dates?.currentValue?.start !==
          this.range.controls.start?.value
        ) {
          this.range.controls.start.setValue(
            changes?.dates?.currentValue?.start,
          );
        }
        if (
          changes?.dates?.currentValue?.end !== this.range.controls.end?.value
        ) {
          this.range.controls.end.setValue(changes?.dates?.currentValue?.end);
        }
      }
      this.expanded = this.dates || this.isExpanded ? true : false;
    }
  }
  public onApply(): void {
    if (this.range.valid) {
      this.onSearch();
    }
  }

  public onClearDate(): void {
    this.dateRangeService.preset = undefined;
    this.range.controls.start.setValue(undefined);
    this.range.controls.end.setValue(undefined);
    this.onSearch();
  }

  private onSearch(): void {
    const preset = this.generatePreset();
    this.dateRangeService.preset = preset;
    this.search.emit(preset);
  }

  private generatePreset(): IPreset {
    const startDate: Moment = this.range.controls.start.value;
    const endDate: Moment = this.range.controls.end.value;
    return {
      start: startDate,
      end: endDate,
      label: this.dateRangeService.getPresetLabel(startDate, endDate),
    };
  }

  public onKeyUp(event: KeyboardEvent): void {
    if (event.key === 'Enter') {
      this.onApply();
    }
  }

  public onOpen(event: MouseEvent): void {
    event.stopImmediatePropagation();
    this.expanded = true;
  }
}
