import { EChartsOption } from 'echarts';
import {
  ChartType,
  PieMode,
  WidgetActions,
} from '../../shared/enums/widget.enum';
import {
  IDataPoint,
  IDisplayType,
  ISerie,
} from '../../shared/interfaces/widget.interface';
import { Widget } from './widget';
import { Chart } from './chart';

export class PieChart extends Chart {
  constructor(public name: ChartType) {
    super(name);
  }
  public getOptions(
    data: Array<ISerie>,
    segment: any,
    settings: { [key: string]: any },
  ): EChartsOption {
    return {
      tooltip: {
        trigger: 'item',
        extraCssText: 'overflow: auto; max-height: 250px; border: 0;',
        enterable: true,
      },
      grid: {
        left: 0,
        top: 0,
        right: 0,
        bottom: 0,
      },
      legend: {
        type: 'scroll',
        icon: 'rect',
        top:
          settings?.width === 1 ||
          settings?.widgetAction !== WidgetActions.RENDER
            ? 'top'
            : 'center',
        orient:
          settings?.width === 1 ||
          settings?.widgetAction !== WidgetActions.RENDER
            ? 'horizontal'
            : 'vertical',
        left:
          settings?.width === 1 ||
          settings?.widgetAction !== WidgetActions.RENDER
            ? 'center'
            : 'left',
        formatter: (value: string) => {
          return value.trim().replace('\n', '').length > 30
            ? value.trim().replace('\n', '').substring(0, 30) + '...'
            : value.trim().replace('\n', '');
        },
      },
      series: data.map((serie: ISerie, index: number) => {
        return {
          name: data[0].name,
          type: 'pie',
          label: {
            position: 'inner',
            formatter: (params: any) => {
              return params.value === 0 ? '' : params.value;
            },
          },
          data: serie.data.map((item: IDataPoint) => {
            return this.getDataPoint(item, segment, serie.tagId, index);
          }),
          radius: '75%',
          center: ['50%', '50%'],
          avoidLabelOverlap: true,
          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: 'rgba(0, 0, 0, 0.5)',
            },
          },
        };
      }),
    };
  }

  public getSelectedType(
    displayTypes: Array<IDisplayType>,
    widget: Widget,
  ): IDisplayType {
    return displayTypes.find((item: IDisplayType) => {
      return item.type === ChartType.PIE && item.options?.mode === PieMode.PIE;
    });
  }
}
