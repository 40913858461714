import { TagCategory } from '@intorqa-ui/core';
import { IDisplayType } from '../interfaces/widget.interface';
import { FilterType } from '../enums/tag.enum';

export const TagTypes = [
  'User',
  'Global',
  'Shared',
  'Document Attributes',
  'Collection Source',
  'System Tag',
  'User Tag',
  'My Tags',
  'Bookmarks',
];
export const FilterTypes = [
  'Actor',
  'Channel',
  'Source',
  'EmitType',
  'Post Type',
  'Community',
];

export const TagManagerFilters: Array<{ id: string; value: string }> = [
  {
    id: FilterType.ALL,
    value: TagCategory.ALL,
  },
  {
    id: FilterType.USER,
    value: 'My tags',
  },
  {
    id: FilterType.SHARED,
    value: TagCategory.Shared,
  },
  {
    id: FilterType.COMMUNITIES,
    value: 'System tag',
  },
  {
    id: FilterType.ALERTS,
    value: 'Tags with alerts',
  },
];
