import { Injectable } from '@angular/core';
import {
  ApiRequestService,
  DTOCreation,
  DTOTypeConverter,
} from '@intorqa-ui/api';
import { ECharts } from 'echarts';
import { Observable, Subject, map } from 'rxjs';
import { ISegment } from '../../widget-settings/interfaces/widget-settings.interface';
import { IWidget, IWidgetData } from '../../shared/interfaces/widget.interface';
import { NavigationHistoryItem } from '../../widget-settings/models/navigation-history-item.model';
import { ResponsivePanels } from '../../widget-settings/models/responsive-panels';
import { TimeSeries } from '../models/time-series';
import { Timeline } from '../../shared/models/timeline';
import { Widget } from '../models/widget';
import { TagAnalysis } from '../models/tag-analysis';
import { TagComparison } from '../models/tag-comparison';
import { ITagTreeNode } from '../../shared/interfaces/tag.interface';
import {
  QueryFilters,
  IError,
  DTOQuery,
  DateQueryType,
} from '@intorqa-ui/core';
import { WidgetFactory } from '@portal/shared/factories/widget.factory';

@Injectable({
  providedIn: 'root',
})
export class WidgetService {
  public updateSegmentObservable = new Subject<ISegment | undefined>();
  public responsivePanels$ = new Subject<void>();
  public addWidget$ = new Subject<Widget>();
  public reload$ = new Subject<{
    widget?: Widget;
    initialState: QueryFilters;
  }>();
  public reloadChart$ = new Subject<Widget | void>();
  public updateApiKeyObservable = new Subject<string>();
  public drilldownObservable = new Subject<NavigationHistoryItem>();
  public chartResizeObservable = new Subject();
  public updateNavigationItem$ = new Subject<NavigationHistoryItem>();
  public changeWidgetDisplayObservable = new Subject<{
    params: QueryFilters;
    widget: Widget;
  }>();
  public timelineDataBoundObservable = new Subject<{
    widget: Widget;
    initialState: QueryFilters;
  }>();
  public drilldownDataBoundObservable = new Subject();
  public loaderObservable = new Subject<{
    widget: Widget | Timeline;
    showLoader: boolean;
  }>();

  private _responsivePanels: ResponsivePanels;

  public get responsivePanels(): ResponsivePanels {
    return this._responsivePanels;
  }

  public set responsivePanels(v: ResponsivePanels) {
    this._responsivePanels = v;
  }

  constructor(private apiRequestService: ApiRequestService) {}

  public getData(
    payload: {
      widget: TagComparison | TagAnalysis | TimeSeries;
      filters: DTOQuery;
    },
    state: any,
  ): Promise<IWidgetData> {
    let pageQuery = '';
    const addQueryParams = (paramName: string, paramValue: any) => {
      if (paramValue && paramName) {
        if (pageQuery) {
          pageQuery += '&';
        }
        pageQuery += `${paramName}=${paramValue}`;
      }
    };

    if (state?.where) {
      addQueryParams('dateFrom', state?.where?.start);
      if (state.where?.label === DateQueryType.Custom) {
        addQueryParams('dateTo', state?.where?.end);
      }
    }

    return new Promise((resolve, reject) => {
      this.apiRequestService
        .post(
          `/widgets/data${pageQuery !== '' ? `?${pageQuery}` : ''}`,
          new DTOTypeConverter<IWidgetData>(),
          JSON.stringify(payload),
          undefined,
          'v2.0',
        )
        .then((response: IWidgetData) => {
          response.series = payload.widget.transformData(response.series);
          resolve(response);
        })
        .catch((error: IError) => reject(error));
    });
  }

  public getDocuments(
    payload: {
      widget: Widget;
      filters: DTOQuery;
    },
    state: any,
  ): Promise<any> {
    let pageQuery = '';
    const addQueryParams = (paramName: string, paramValue: any) => {
      if (paramValue && paramName) {
        if (pageQuery) {
          pageQuery += '&';
        }
        pageQuery += `${paramName}=${paramValue}`;
      }
    };

    addQueryParams('pageSize', state?.pageSize);
    addQueryParams('page', state?.page);
    addQueryParams('dateFrom', state?.where?.start);
    addQueryParams('dateTo', state?.where?.end);
    addQueryParams('dataValue', state?.dataValue);
    return this.apiRequestService.post(
      `/widgets/docs${pageQuery !== '' ? `?${pageQuery}` : ''}`,
      new DTOTypeConverter<DTOCreation>(),
      JSON.stringify(payload),
      undefined,
      'v2.0',
    );
  }

  public createWidget(
    widget: any,
  ): Observable<TimeSeries | TagComparison | TagAnalysis | Timeline> {
    return this.apiRequestService
      .postToObservable(
        '/widgets',
        new DTOTypeConverter<any>(),
        JSON.stringify(widget),
        undefined,
        'v2.0',
      )
      .pipe(
        map((response: IWidget) => {
          return WidgetFactory.createObject(response);
        }),
      );
  }

  public updateWidget(widget: Widget): Observable<Widget> {
    return this.apiRequestService.putToObservable(
      '/widgets/' + widget.widgetId,
      JSON.stringify(widget),
      undefined,
      'v2.0',
    );
  }

  public exportChart(title: string, chartInstance: ECharts): void {
    const exportImage = (dataUrl: string) => {
      const img = new Image();
      img.src = dataUrl;
      const element = document.createElement('a');
      element.setAttribute('href', img.src);
      element.setAttribute('download', title + '.png');
      element.style.display = 'none';
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
    };

    exportImage(
      chartInstance.getDataURL({
        pixelRatio: 2,
        backgroundColor: 'white',
      }),
    );
  }

  public getDependants(tagId: string): Observable<ITagTreeNode> {
    return this.apiRequestService.getToObservable(
      `/widgets/${tagId}/dependants`,
      new DTOTypeConverter<ITagTreeNode>(),
      undefined,
      'v2.0',
    );
  }

  public getDependencies(tagId: string): Observable<ITagTreeNode> {
    return this.apiRequestService.getToObservable(
      `/widgets/${tagId}/dependencies`,
      new DTOTypeConverter<ITagTreeNode>(),
      undefined,
      'v2.0',
    );
  }

  public unlinkTag(
    unlinkId: string,
    dependencyId: string,
  ): Observable<ITagTreeNode> {
    return this.apiRequestService.putToObservable(
      `/widgets/${unlinkId}/unlink/${dependencyId}`,
      new DTOTypeConverter<ITagTreeNode>(),
      undefined,
      'v2.0',
    );
  }
}
