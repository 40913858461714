<div
  [ngClass]="[
    'flex',
    'items-center',
    'border',
    'border-solid',
    'border-border',
    'rounded',
    'bg-white',
    class
  ]"
  (click)="$event.stopPropagation()"
>
  <mat-date-range-input
    class="mx-2"
    [formGroup]="range"
    [rangePicker]="picker"
    [disabled]="disabled"
    [min]="minDate"
    [max]="maxDate"
  >
    <input
      matStartDate
      formControlName="start"
      placeholder="Start date"
      [errorStateMatcher]="customErrorStateMatcher"
    />
    <input matEndDate formControlName="end" placeholder="End date" />
  </mat-date-range-input>
  <itq-mat-button
    [ngStyle]="{ margin: '1px' }"
    [materialStyle]="'basic'"
    [size]="Sizes.SM"
    [padding]="Sizes.NONE"
    matSuffix
    [disabled]="disabled"
    [for]="picker"
    (clickEvent)="picker.open()"
  >
    <fa-icon [size]="'lg'" [icon]="['far', 'calendar']"></fa-icon>
  </itq-mat-button>
  @if (range?.controls?.start?.value && range?.controls?.end?.value) {
    <button
      [ngStyle]="{ width: '30px', height: '30px', minWidth: '30px' }"
      class="p-0!"
      [type]="'button'"
      (click)="onClearDate()"
    >
      <fa-icon [icon]="['far', 'times']" matDatepickerToggleIcon></fa-icon>
    </button>
  }

  <mat-date-range-picker
    #picker
    [calendarHeaderComponent]="DateRangeHeaderComponent"
    (closed)="onApply()"
  >
  </mat-date-range-picker>
  <mat-error *ngIf="!range.valid && range.touched"
    >Invalid date range</mat-error
  >
</div>
