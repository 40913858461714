import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IconName } from '@fortawesome/fontawesome-svg-core';
import { Sizes } from '../../enums/shared.enum';

@Component({
  selector: 'itq-thumbnail',
  templateUrl: './thumbnail.component.html',
  styleUrls: ['./thumbnail.component.scss'],
})
export class ThumbnailComponent implements OnInit {
  @Input() icon: IconName;
  @Input() title: string;
  @Input() description: string;
  @Input() disabled: boolean;

  @Output() add = new EventEmitter<void>();

  readonly Sizes = Sizes;

  constructor() {}

  ngOnInit() {}

  public onAdd(): void {
    this.add.emit();
  }
}
