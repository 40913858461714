<table
  cellpadding="0"
  cellspacing="0"
  [ngClass]="[headerVisible ? 'show-header' : '', theme]"
  #grid
>
  <thead>
    <tr>
      <ng-container
        *ngFor="let item of tableColumns; trackBy: trackByFn; let i = index"
      >
        <ng-container *ngIf="item.show !== false">
          <th
            [class.text-right]="item.position === 'right'"
            [class.text-center]="item.position === 'center'"
            [class.text-left]="item.position === 'left'"
            [ngStyle]="{
              width:
                item.width && item.width !== 'fit' ? item.width : undefined,
              flex: item.width && item.width === 'fit' ? 1 : undefined,
              padding: item.padding,
              position: item.sticky ? 'sticky' : undefined
            }"
            (click)="onSort(item)"
          >
            <div
              class="mat-sort-header-content cell__container"
              [ngStyle]="{
                'justify-content':
                  item.position !== 'center' ? item.position : 'center'
              }"
            >
              @if (item.name) {
                <span
                  [ngClass]="[
                    'header__container',
                    'w-full',
                    i === 0 ? 'pl-7' : ''
                  ]"
                  >{{ item.name }}</span
                >
              }
              @if (initialState?.sort?.active === item.dataKey) {
                <fa-icon
                  [ngClass]="{
                    'px-4': true,
                    flex: true,
                    invisible: initialState?.sort?.active !== item.dataKey
                  }"
                  [icon]="[
                    'far',
                    initialState?.sort?.direction === 'asc'
                      ? 'arrow-up'
                      : 'arrow-down'
                  ]"
                ></fa-icon>
              }
            </div>
          </th>
        </ng-container>
      </ng-container>
    </tr>
    <tr *ngIf="isFilterable">
      <ng-container *ngFor="let item of tableColumns; trackBy: trackByFn">
        <ng-container *ngIf="item.show !== false">
          <td
            [ngStyle]="{
              width:
                item.width && item.width !== 'fit' ? item.width : undefined,
              flex: item.width && item.width === 'fit' ? 1 : undefined,
              padding: item.padding || '0 0 0 1rem',
              position: item.sticky ? 'sticky' : undefined
            }"
          >
            <div class="search__container" *ngIf="item.isFilterable">
              <itq-multiple-dropdown
                *ngIf="item.searchComponent === ComponentType.MULTIPLE_DROPDOWN"
                [dataSource]="item.dataSource"
                [(ngModel)]="item.searchValue"
                [dataFields]="{ name: 'name', value: 'value' }"
                [httpBinding]="item.httpBinding"
                (changeValue)="onMultipleDropdownChange(item, $event)"
                (dataBound)="onDataBound(item)"
              ></itq-multiple-dropdown>
              <itq-input
                *ngIf="item.searchComponent === ComponentType.INPUT"
                [type]="item.searchComponentType || 'text'"
                [(ngModel)]="item.searchValue"
                [placeholder]="'Search...'"
                (search)="onApplySearch(item)"
                (clear)="onClearSearch(item)"
              ></itq-input>
              <itq-dropdown
                *ngIf="item.searchComponent === ComponentType.DROPDOWN"
                [(ngModel)]="item.searchValue"
                [dataSource]="item.dataSource"
                [dataFields]="{ name: 'name', value: 'value' }"
                [httpBinding]="item.httpBinding"
                [width]="'fit'"
                (dataBound)="onDataBound(item)"
                (changeValue)="onChangeValue(item, $event)"
                (clear)="onChangeValue(item, undefined)"
              ></itq-dropdown>
              <div
                class="date__picker_container"
                *ngIf="item.searchComponent === ComponentType.DATE"
              >
                <itq-date-picker
                  [(ngModel)]="item.searchValue"
                  (changeValue)="onApplySearch(item)"
                ></itq-date-picker>
              </div>
            </div>
          </td>
        </ng-container>
      </ng-container>
    </tr>
  </thead>
  <tbody
    #tbody
    [ngClass]="{ 'no-results': dataSource?.length === 0 || !dataSource }"
  >
    @for (item of dataSource; track trackByFn) {
      <tr
        [ngClass]="{
          table_row: true,
          success: item.success,
          error: item.error,
          active: active && active === trackBy
        }"
        (click)="onRowClick(item)"
      >
        <ng-container
          *ngFor="let column of tableColumns; trackBy: trackByFn; let i = index"
        >
          @if (column.show !== false) {
            <td
              [class.text-right]="column.position === 'right'"
              [class.text-center]="column.position === 'center'"
              [class.text-left]="column.position === 'left'"
              [ngClass]="column.cssClass"
              [ngStyle]="{
                'justify-content':
                  column.position !== 'center' ? column.position : 'center',
                width:
                  column.width && column.width !== 'fit'
                    ? column.width
                    : undefined,
                flex: column.width && column.width === 'fit' ? 1 : undefined,
                padding: column.padding,
                position: column.sticky ? 'sticky' : undefined
              }"
            >
              <div
                [ngClass]="[
                  i === 0 ? 'ml-7' : '',
                  i === tableColumns.length - 1 ? 'last-column' : '',
                  'flex',
                  'h-full',
                  'items-center',
                  'w-full',
                  'border-b',
                  'border-border',
                  'body_column',
                  column.position === 'center' ? 'justify-center' : '',
                  column.position === 'left' ? 'start' : '',
                  column.position === 'right' ? 'end' : ''
                ]"
              >
                @if (column.customRender) {
                  <ng-container
                    *ngTemplateOutlet="
                      column.template;
                      context: {
                        $implicit: item,
                        index: i,
                        data: column.templateData
                      }
                    "
                  ></ng-container>
                } @else {
                  <span>{{ item | dataPropertyGetter: column.dataKey }}</span>
                }
              </div>
            </td>
          }
        </ng-container>
      </tr>
    }
    @if (dataSource && dataSource?.length === 0) {
      <tr>
        <td>
          <itq-panel-info
            [label]="emptyMessage"
            [message]="emptyMessageDescription"
            [icon]="'exclamation-circle'"
          ></itq-panel-info>
        </td>
      </tr>
    }
  </tbody>
  @if (footerTemplate) {
    <tfoot>
      <tr>
        <td [attr.colspan]="tableColumns?.length">
          <ng-container *ngTemplateOutlet="footerTemplate"></ng-container>
        </td>
      </tr>
    </tfoot>
  }
</table>
