<div class="flex relative flex-col flex-1">
  <itq-input
    class="p-4"
    [(ngModel)]="query"
    [icon]="'search'"
    [style]="'link'"
    [placeholder]="'Please type to filter...'"
  ></itq-input>
  @if (allowDrag) {
    <mat-list
      class="border-t border-border overflow-auto"
      #selectionsList
      cdkDropList
      (cdkDropListDropped)="onDrop($event)"
      (scroll)="onScroll()"
    >
      @for (item of dataSource; track item[dataFields.value]) {
        <mat-list-item
          class="cursor-pointer hover:bg-gray-200 items-center"
          cdkDrag
          (click)="onListItemClick(item)"
        >
          <div class="drag-placeholder" *cdkDragPlaceholder></div>
          <itq-icon
            *ngIf="item.icon"
            [iconName]="item.icon"
            [iconType]="item.iconType"
          ></itq-icon>
          <span>
            {{ dataFields ? item[dataFields.name] : item }}
          </span>
        </mat-list-item>
      }
    </mat-list>
  } @else {
    <mat-list
      #selectionsList
      class="border-t border-border"
      (scroll)="onScroll()"
    >
      @for (item of dataSource; track item[dataFields.value]) {
        <mat-list-item class="cursor-pointer hover:bg-gray-200 items-center">
          <itq-icon
            *ngIf="item.icon"
            [iconName]="item.icon"
            [iconType]="item.iconType"
          ></itq-icon>
          <span>
            {{ dataFields ? item[dataFields.name] : item }}
          </span>
        </mat-list-item>
      }
    </mat-list>
  }

  <itq-panel-info
    *ngIf="dataSource && dataSource.length === 0"
    [label]="'No items found!'"
    [message]="'Please update your query...'"
    [icon]="'exclamation-circle'"
  ></itq-panel-info>
</div>
