<ng-container *ngIf="navigationHistory?.items?.length > 1">
  <itq-sidebar
    class="navigation_history__container"
    [isExpanded]="expandedNavigation"
    (toggle)="onToggle()"
  >
    <itq-navigation-history
      class="p-4"
      [selected]="selectedNavigationHistory"
      [dataFields]="{ name: 'name', value: 'name' }"
      [dataSource]="navigationHistory?.items"
      (loadHistory)="onLoadHistoryItem($event)"
      (deleteItem)="onDeleteHistoryItem($event)"
    ></itq-navigation-history>
  </itq-sidebar>
</ng-container>

<ng-container [ngSwitch]="selectedNavigationHistory?.item?.type">
  <ng-container *ngSwitchCase="AnalysisTypes.TIMELINE">
    <itq-widget-settings-timeline
      [navigationItem]="selectedNavigationHistory"
      [navigationHistory]="navigationHistory"
      [articleDetail]="articleDetail"
      [form]="form"
    ></itq-widget-settings-timeline>
  </ng-container>
  <ng-container *ngSwitchCase="AnalysisTypes.PROFILE">
    <itq-widget-settings-profiles
      [articleDetail]="articleDetail"
      [navigationItem]="selectedNavigationHistory"
      [navigationHistory]="navigationHistory"
      [form]="form"
    ></itq-widget-settings-profiles>
  </ng-container>
  <ng-container *ngSwitchCase="AnalysisTypes.NOTIFICATION">
    <itq-notifications-explore
      [form]="form"
      [navigationItem]="selectedNavigationHistory"
      [articleDetail]="articleDetail"
    ></itq-notifications-explore>
  </ng-container>
  <ng-container *ngSwitchDefault>
    <itq-widget-settings-chart
      [navigationItem]="selectedNavigationHistory"
      [navigationHistory]="navigationHistory"
      [form]="form"
      [segment]="segment"
      [articleDetail]="articleDetail"
    >
    </itq-widget-settings-chart>
  </ng-container>
</ng-container>
@if(showLoader) {
<itq-loader ></itq-loader>
}
