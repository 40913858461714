import { cloneDeep } from 'lodash';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { Subscription, fromEvent } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { IQueryColumn } from '../../interfaces/query-filters';
import { TableColumn } from '../../interfaces/table.interface';
import { QueryFilters } from '../../models/query-filters';
import { VirtualScrollService } from '../../services/virtual-scrolling.service';
import { Sizes } from '../../enums/shared.enum';
import { ComponentType } from '../../enums/table.enum';
import { IDropdownItem } from '../dropdown/dropdown.interface';
import { SCROLL_DIRECTION } from './virtual-table.enum';

@Component({
  selector: 'itq-virtual-table',
  templateUrl: './virtual-table.component.html',
  styleUrls: ['./virtual-table.component.scss'],
})
export class VirtualTableComponent
  implements OnInit, OnChanges, AfterViewInit, OnDestroy
{
  @Output() dataBound = new EventEmitter<QueryFilters>();
  @Output() sort = new EventEmitter<void>();
  @Output() open = new EventEmitter<void>();
  @Output() rowClick = new EventEmitter<any>();
  @Output() updateRef = new EventEmitter<ElementRef>();

  @Input() dataSource: Array<any> = [];
  @Input() tableColumns: Array<TableColumn>;
  @Input() totalCount: number;
  @Input() initialState: QueryFilters;
  @Input() trackBy: string;
  @Input() emptyMessage = 'No results found';
  @Input() emptyMessageDescription =
    'Please update search term to show some results.';
  @Input() headerVisible = true;
  @Input() isFilterable = false;
  @Input() theme = 'primary';
  @Input() footerTemplate: TemplateRef<any>;
  @Input() allowScrollingUp = false;
  @Input() active: string;

  @ViewChild('tbody') tbody: ElementRef;

  private scrollTop = 0;
  private dataBoundSubscription: Subscription;
  private scrollSubscription: Subscription;
  private mousewheelSubscription: Subscription;
  public showFilters = false;
  private elementTop = 0;
  private initialPage: number;
  private lastPage: number;
  private scrollLocked = false;

  readonly ComponentType = ComponentType;
  readonly Sizes = Sizes;

  @ViewChild('grid') grid: ElementRef;

  constructor(
    private virtualScrollService: VirtualScrollService,
    readonly cdr: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.dataBoundSubscription =
      this.virtualScrollService.dataBoundObservable.subscribe(() => {
        if (!this.active) {
          this.scrollTop = 0;
          this.tbody?.nativeElement?.scrollTo(0, 0);
          this.lastPage = 1;
        }
      });
  }

  ngAfterViewInit(): void {
    const scroll = fromEvent(this.tbody.nativeElement, 'scroll');
    const resultScroll = scroll.pipe(debounceTime(100), distinctUntilChanged());
    this.scrollSubscription = resultScroll.subscribe(() => {
      this.onScroll();
    });
    if (this.allowScrollingUp) {
      const mousewheel = fromEvent(this.tbody.nativeElement, 'wheel');
      const result = mousewheel.pipe(debounceTime(100), distinctUntilChanged());
      this.mousewheelSubscription = result.subscribe(() => {
        this.onScroll();
      });
    }
    this.updateRef.emit(this.grid);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes?.allowScrollingUp?.previousValue !==
      changes?.allowScrollingUp?.currentValue
    ) {
      setTimeout(() => {
        const mousewheel = fromEvent(this.tbody.nativeElement, 'wheel');
        const result = mousewheel.pipe(
          debounceTime(100),
          distinctUntilChanged(),
        );
        this.mousewheelSubscription?.unsubscribe();
        this.mousewheelSubscription = result.subscribe(() => {
          this.onScroll();
        });
      }, 100);
    }
    if (
      changes?.dataSource?.currentValue !== changes.dataSource?.previousValue
    ) {
      this.cdr.detectChanges();
      let elements: Array<HTMLElement> = [];
      if (
        this.tbody.nativeElement.scrollTop === 0 &&
        this.dataSource?.length > this.initialState.pageSize
      ) {
        elements =
          this.tbody?.nativeElement?.getElementsByClassName('table_row');
        if (elements?.length > 0) {
          this.tbody.nativeElement.scrollTop =
            elements[this.initialState.pageSize - 1].offsetTop -
            this.tbody.nativeElement.clientHeight / 2;
          this.scrollTop = this.tbody.nativeElement.scrollTop;
        }
      }
      if (
        this.active &&
        this.dataSource?.length <= this.initialState?.pageSize
      ) {
        elements = this.tbody?.nativeElement?.getElementsByClassName('active');
        if (elements?.length > 0) {
          this.tbody.nativeElement.scrollTop =
            elements[0].offsetTop - this.tbody.nativeElement.clientHeight / 2;
          this.scrollTop = this.tbody.nativeElement.scrollTop;
        }
      }
      if (
        !this.initialPage &&
        this.initialState?.page &&
        this.allowScrollingUp
      ) {
        this.initialPage = this.initialState.page;
        this.lastPage = this.initialState.page;
      }
      this.scrollLocked = false;
      if (!this.allowScrollingUp) {
        if (
          !this.allowScrollingUp &&
          changes?.dataSource?.currentValue?.length ===
            this.initialState.pageSize
        ) {
          this.resetScroll();
        }
        if (changes?.dataSource?.currentValue?.length > 0) {
          this.dataSource = changes?.dataSource?.currentValue?.slice(
            0,
            this.initialState.page * this.initialState.pageSize,
          );
        }
      }
    }
  }

  ngOnDestroy(): void {
    this.dataBoundSubscription.unsubscribe();
    this.scrollSubscription?.unsubscribe();
    if (this.allowScrollingUp) {
      this.mousewheelSubscription?.unsubscribe();
    }
  }

  private resetScroll(): void {
    this.scrollTop = 0;
    this.initialState.page = 1;
    this.tbody?.nativeElement?.scrollTo(0, 0);
  }

  public onScroll(): void {
    if (!this.scrollLocked) {
      if (this.initialState) {
        const scrollDirection = this.getScrollDirection();
        if (scrollDirection === SCROLL_DIRECTION.DOWN) {
          const isScrollEnd =
            this.tbody.nativeElement.offsetHeight +
              this.tbody.nativeElement.scrollTop >=
            this.tbody.nativeElement.scrollHeight - 1;
          if (
            this.lastPage &&
            isScrollEnd &&
            this.initialState.page < this.lastPage
          ) {
            this.initialState.page = this.lastPage;
          }
          this.virtualScrollService
            .scrollDown(
              this.tbody.nativeElement,
              this.initialState.pageSize,
              this.totalCount,
              this.scrollTop,
              undefined,
              this.initialState.page,
            )
            .then((response: { scroll: boolean; scrollTop: number }) => {
              if (response.scroll) {
                this.initialState.page += 1;
                this.lastPage = cloneDeep(this.initialState.page);
                this.scrollTop = response.scrollTop;
                this.scrollLocked = true;
                this.dataBound.emit(this.initialState);
                this.virtualScrollService.scroll$.next({
                  query: this.initialState,
                  direction: 'down',
                });
              }
            });
        } else {
          if (this.allowScrollingUp) {
            if (this.tbody.nativeElement.scrollTop === 0) {
              this.initialState.page = this.initialPage;
            }
            this.virtualScrollService
              .scrollUp(
                this.tbody.nativeElement,
                undefined,
                this.initialState.page,
              )
              .then((response: { scroll: boolean; scrollTop: number }) => {
                if (response.scroll) {
                  if (this.initialState.page > 1) {
                    this.initialState.page -= 1;
                    this.initialPage = cloneDeep(this.initialState.page);
                  }
                  this.scrollTop = response.scrollTop;
                  this.scrollLocked = true;
                  this.dataBound.emit(this.initialState);
                  this.virtualScrollService.scroll$.next({
                    query: this.initialState,
                    direction: 'up',
                  });
                }
              });
          }
        }
      }
    }
  }

  private getScrollDirection(): SCROLL_DIRECTION {
    let direction: SCROLL_DIRECTION;
    if (this.tbody.nativeElement.scrollTop > this.elementTop) {
      direction = SCROLL_DIRECTION.DOWN;
    } else {
      direction = SCROLL_DIRECTION.UP;
    }
    this.elementTop = this.tbody.nativeElement.scrollTop;
    return direction;
  }

  trackByFn(index: number, item: any): void {
    if (this.trackBy) {
      return item[this.trackBy];
    }
  }

  onSort(item: TableColumn): void {
    if (item.isSortable) {
      this.initialState.sort = {
        active: item.searchField?.toString() || item.dataKey.toString(),
        direction:
          this.initialState?.sort?.direction === 'asc' ? 'desc' : 'asc',
        isMetadata: item.isMetadata,
      };
      this.sort.emit();
    }
  }

  public onClearSearch(params: TableColumn): void {
    const columns = this.initialState.query as Array<IQueryColumn>;
    const queryColumn = columns.find((item: IQueryColumn) => {
      return item.searchField === params.searchField;
    });
    if (queryColumn) {
      this.initialState.removeQueryColumn(queryColumn);
    }

    this.dataBound.emit(this.initialState);
  }

  public onApplySearch(params: TableColumn): void {
    if (!params.searchValue || params.searchValue === '') {
      this.onClearSearch(params);
    } else {
      this.initialState.addQueryColumn({
        searchValues: [params.searchValue],
        searchField: params.searchField,
        searchFieldType: params.searchFieldType,
        isMetadata: params.isMetadata,
      });
      this.dataBound.emit(this.initialState);
    }
  }

  public onChangeValue(
    params: TableColumn,
    value: IDropdownItem | string,
  ): void {
    if (typeof value !== 'string') {
      value = value as IDropdownItem;
      value = value?.value;
    }
    if (!value || value === '') {
      this.onClearSearch(params);
    } else {
      this.initialState.addQueryColumn({
        searchValues: [value],
        searchField: params.searchField,
        searchFieldType: params.searchFieldType,
        isMetadata: params.isMetadata,
      });
      this.dataBound.emit(this.initialState);
    }
  }

  public onMultipleDropdownChange(
    params: TableColumn,
    value: IDropdownItem,
  ): void {
    const columns = this.initialState.query as Array<IQueryColumn>;
    const queryColumn = columns.find((item: IQueryColumn) => {
      return item.searchField === params.searchField;
    });
    if (queryColumn) {
      const target = queryColumn.searchValues.find((item: string) => {
        return item === value?.value;
      });
      if (target) {
        queryColumn.searchValues = queryColumn.searchValues.filter(
          (item: string) => {
            return item !== value?.value;
          },
        );
        if (
          !queryColumn?.searchValues ||
          queryColumn?.searchValues?.length === 0
        ) {
          this.initialState.removeQueryColumn(queryColumn);
        }
      } else {
        queryColumn.searchValues.push(value?.value);
      }
    } else {
      this.initialState.addQueryColumn({
        searchValues: [value?.value],
        searchField: params.searchField,
        searchFieldType: params.searchFieldType,
        isMetadata: params.isMetadata,
      });
    }

    this.dataBound.emit(this.initialState);
  }

  public onDataBound(column: TableColumn): void {
    column?.dataBound();
  }

  public onRowClick(row: any): void {
    this.rowClick.emit(row);
  }
}
