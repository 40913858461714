<div class="flex w-full" #chartContainer>
  @if (!data || data?.totalHits > 0) {
    <div
      class="w-full h-full"
      echarts
      [options]="options"
      [theme]="theme"
      [autoResize]="true"
      (chartClick)="onChartClick($event)"
      (chartInit)="onChartInit($event)"
    ></div>
  } @else {
    @if (data && data?.totalHits === 0) {
      <itq-panel-info
        class="flex w-full"
        [label]="noResultsTitle"
        [message]="noResultsMessage"
        [icon]="'exclamation-circle'"
      ></itq-panel-info>
    }
  }
</div>
