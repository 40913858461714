<div class="flex justify-between">
  <fa-icon [size]="'3x'" [icon]="['far', icon]"></fa-icon>
  <itq-mat-button
    [padding]="Sizes.NONE"
    [materialStyle]="'flat'"
    [style]="'primary'"
    [type]="'button'"
    [disabled]="disabled"
    (clickEvent)="onAdd()"
  >
    <fa-icon [icon]="['far', 'plus']"></fa-icon>
  </itq-mat-button>
</div>
<div class="font-bold text-15 pt-3">{{ title }}</div>
<div class="text-border font-normal text-13 text-metadata">
  {{ description }}
</div>
