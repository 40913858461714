// Angular modules
import { DatePipe, DecimalPipe } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { YouTubePlayerModule } from '@angular/youtube-player';
import {
  AngularModule,
  CoreModule,
  FAwesomeModule,
  FormatDatePipe,
  HeadLineSimpleComponent,
  HighlightSearchPipe,
  MaterialModule,
  SvgComponent,
} from '@intorqa-ui/core';
import { QuillModule } from 'ngx-quill';
import { DocumentItemDetailComponent } from './components/document-item-detail/document-item-detail.component';
import { DocumentItemTileComponent } from './components/document-item-tile/document-item-tile.component';

import { SortTableDataPipe } from './components/chart/chart.pipe';
import {
  CustomSortExtensionFieldsPipe,
  FilterEmptyExtensionFieldsPipe,
  GetExtensionFieldNamePipe,
  GetExtensionFieldValuesPipe,
} from './components/document-item-detail/document-item-detail.pipe';
import { ShareEmailComponent } from './components/share-email/share-email.component';
import { DefaultImage } from './directives/image-default.directive';

import { TagsIncludeSelectionComponent } from './components/tags-include-selection/tags-include-selection.component';

import { SearchResultsComponent } from './components/search-results/search-results.component';
import {
  GetSearchTermPipe,
  IsDiscordNavigationPipe,
} from './components/search-results/search-results.pipe';
import { AddNotesComponent } from './components/add-notes/add-notes.component';
import { PostNotesWizardComponent } from './components/post-notes-wizard/post-notes-wizard.component';
import {
  TransformMetadataValuesPipe,
  GetTrendIconColor,
  GetTrendIconPipe,
  GetProfileTypeIconPipe,
} from '@portal/profiles/pipes/profiles.pipe';
import { FindActionPipe } from './components/toolbar/toolbar.pipe';
import { ToolbarComponent } from './components/toolbar/toolbar.component';

@NgModule({
  imports: [
    FAwesomeModule,
    AngularModule,
    MaterialModule,
    CoreModule,
    HeadLineSimpleComponent,
    YouTubePlayerModule,
    FormatDatePipe,
    QuillModule.forRoot({
      customOptions: [
        {
          import: 'formats/font',
          whitelist: [
            'mirza',
            'roboto',
            'aref',
            'serif',
            'sansserif',
            'monospace',
          ],
        },
      ],
    }),
    GetProfileTypeIconPipe,
    SvgComponent,
  ],
  declarations: [
    ShareEmailComponent,
    DocumentItemTileComponent,
    DocumentItemDetailComponent,
    DefaultImage,
    SearchResultsComponent,
    TransformMetadataValuesPipe,
    HighlightSearchPipe,
    CustomSortExtensionFieldsPipe,
    FilterEmptyExtensionFieldsPipe,
    SortTableDataPipe,
    TagsIncludeSelectionComponent,
    GetExtensionFieldNamePipe,
    GetSearchTermPipe,
    GetExtensionFieldValuesPipe,
    AddNotesComponent,
    GetTrendIconColor,
    PostNotesWizardComponent,
    IsDiscordNavigationPipe,
    GetTrendIconPipe,
    FindActionPipe,
    ToolbarComponent,
  ],
  exports: [
    ShareEmailComponent,
    DocumentItemTileComponent,
    DocumentItemDetailComponent,
    DefaultImage,
    TransformMetadataValuesPipe,
    GetTrendIconColor,
    HighlightSearchPipe,
    CustomSortExtensionFieldsPipe,
    FilterEmptyExtensionFieldsPipe,
    TagsIncludeSelectionComponent,
    SearchResultsComponent,
    GetSearchTermPipe,
    AddNotesComponent,
    PostNotesWizardComponent,
    GetTrendIconPipe,
    FindActionPipe,
    ToolbarComponent,
  ],
  providers: [DatePipe, DecimalPipe, FindActionPipe],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SharedModule {
  constructor() {}
}
