import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { IconName } from '@fortawesome/fontawesome-svg-core';
import { ComponentType, DateRange, IPill } from '@intorqa-ui/core';
import { ProfileTypeIcon } from '../enums/profile.enum';
import { MetadataFields } from '../enums/profiles-metadata.enum';
import { ProfileMetadata } from '../models/profile-metadata';

@Pipe({
  name: 'transformMetadataValues',
})
export class TransformMetadataValuesPipe implements PipeTransform {
  constructor(private datePipe: DatePipe) {}

  transform(metadata: ProfileMetadata): Array<IPill> | undefined {
    if (!metadata?.values) {
      return undefined;
    }
    const values = JSON.parse(metadata?.values);
    if (metadata.component === ComponentType.MULTIPLE_DROPDOWN) {
      return values.map((item: any) => ({
        name: item.name,
        tooltip: undefined,
      }));
    }
    if (metadata.component === ComponentType.DROPDOWN) {
      return [
        {
          name: values,
          tooltip: undefined,
        },
      ];
    }
    if (metadata.component === ComponentType.DATE) {
      return [
        {
          name: this.datePipe.transform(
            DateRange.convertFromEpochSeconds(values),
            'yyyy MMM dd',
          ),
          tooltip: undefined,
        },
      ];
    } else return undefined;
  }
}

@Pipe({
  name: 'getProfileTypeIcon',
  standalone: true,
})
export class GetProfileTypeIconPipe implements PipeTransform {
  constructor() {}

  transform(typeName: string): IconName {
    return ProfileTypeIcon[typeName];
  }
}

@Pipe({
  name: 'getTrendIcon',
})
export class GetTrendIconPipe implements PipeTransform {
  constructor() {}

  transform(trend: number): IconName {
    if (trend === 0) {
      return 'equals';
    }
    if (trend < 0) {
      return 'chevron-down';
    }
    return 'chevron-up';
  }
}

@Pipe({
  name: 'getTrendColor',
})
export class GetTrendIconColor implements PipeTransform {
  constructor() {}

  transform(trend: number): string {
    if (trend === 0) {
      return 'black';
    }
    if (trend < 0) {
      return '#e85e6c';
    }
    return '#1de62a';
  }
}

@Pipe({
  name: 'getMetadataField',
})
export class GetMetadataFieldPipe implements PipeTransform {
  transform(
    metadata: Array<ProfileMetadata>,
    field: MetadataFields,
  ): ProfileMetadata {
    return metadata?.find((item: ProfileMetadata) => item.name === field);
  }
}
