import { Injectable, Injector, ComponentRef } from '@angular/core';
import {
  Overlay,
  OverlayConfig,
  OverlayRef,
  GlobalPositionStrategy,
} from '@angular/cdk/overlay';
import { ComponentPortal, PortalInjector } from '@angular/cdk/portal';

import { CustomOverlayRef } from './custom-overlay.ref';
import { CUSTOM_OVERLAY_DATA } from './custom-overlay.tokens';

import { CustomOverlayType } from './custom-overlay.type';
import { CustomOverlayConfig } from './custom-overlay.interface';
import { CustomOverlayComponent } from './custom-overlay.component';

const DEFAULT_CONFIG: CustomOverlayConfig = {
  type: CustomOverlayType.DEFAULT,
  hasBackdrop: true,
  backdropClass: [],
  panelClass: [],
  data: null,
  component: null,
  positionStrategy: null,
  disposeOnNavigation: true,
  size: 'md',
  closeOnBackdropClick: true,
};

@Injectable({
  providedIn: 'root',
})
export class CustomOverlayService {
  private customOverlay: CustomOverlayRef;

  constructor(
    private injector: Injector,
    private overlay: Overlay,
  ) {}

  open(
    config: CustomOverlayConfig = { type: CustomOverlayType.DEFAULT },
  ): CustomOverlayRef {
    // Override default configuration
    const dialogConfig = { ...{ ...DEFAULT_CONFIG }, ...config };
    // Sort out backdropClass & panelClass & body class;
    window.document.body.classList.add(`cdk-overlay-open-${dialogConfig.type}`);
    if (!dialogConfig) {
      dialogConfig.backdropClass = [
        'cdk-overlay-backdrop',
        'cdk-overlay-backdrop-modal',
      ];
    } else {
      dialogConfig.backdropClass.push('cdk-overlay-backdrop');
      dialogConfig.backdropClass.push('cdk-overlay-backdrop-modal');
    }
    dialogConfig.panelClass = ['cdk-overlay-pane'];
    dialogConfig.backdropClass.push(`${dialogConfig.type}-backdrop`);
    dialogConfig.panelClass.push(`${dialogConfig.type}-panel`);
    dialogConfig.panelClass.push(dialogConfig.size);
    dialogConfig.closeBtnStyle = 'mini-fab';

    // Returns an OverlayRef which is a PortalHost
    const overlayRef = this.createOverlay(dialogConfig);

    // Instantiate remote control
    const dialogRef = new CustomOverlayRef(overlayRef);

    this.attachDialogContainer(overlayRef, dialogConfig, dialogRef);

    if (dialogConfig.closeOnBackdropClick) {
      overlayRef.backdropClick().subscribe(() => {
        dialogRef.close();
      });
    }

    return dialogRef;
  }

  private createOverlay(config: CustomOverlayConfig): OverlayRef {
    const overlayConfig = this.getOverlayConfig(config);
    return this.overlay.create(overlayConfig);
  }

  private attachDialogContainer(
    overlayRef: OverlayRef,
    config: CustomOverlayConfig,
    dialogRef: CustomOverlayRef,
  ): ComponentRef<any> {
    const injector = this.createInjector(config, dialogRef);

    const containerPortal = new ComponentPortal(
      CustomOverlayComponent,
      null,
      injector,
    );
    const containerRef: ComponentRef<any> = overlayRef.attach(containerPortal);
    return containerRef.instance;
  }

  private createInjector(
    config: CustomOverlayConfig,
    dialogRef: CustomOverlayRef,
  ): PortalInjector {
    const injectionTokens = new WeakMap();

    injectionTokens.set(CustomOverlayRef, dialogRef);
    injectionTokens.set(CUSTOM_OVERLAY_DATA, config);

    return new PortalInjector(this.injector, injectionTokens);
  }

  private getOverlayConfig(config: CustomOverlayConfig): OverlayConfig {
    const positionStrategy = config.positionStrategy
      ? config.positionStrategy
      : this.getPositionStrategy(config);
    const overlayConfig = new OverlayConfig({
      hasBackdrop: config.hasBackdrop,
      backdropClass: config.backdropClass,
      panelClass: config.panelClass,
      scrollStrategy: this.overlay.scrollStrategies.block(),
      positionStrategy,
    });

    return overlayConfig;
  }

  private getPositionStrategy(
    config: CustomOverlayConfig,
  ): GlobalPositionStrategy {
    switch (config.type) {
      case CustomOverlayType['slide-right']:
        return this.overlay.position().global().right().top();
      case CustomOverlayType['slide-left']:
        return this.overlay.position().global().left().top();
      case CustomOverlayType['almost-full']:
      default:
        return this.overlay
          .position()
          .global()
          .centerHorizontally()
          .centerVertically();
    }
  }

  openCustom(data: any, component: any, cb?: any): CustomOverlayRef {
    this.customOverlay = this.open({
      data,
      closeBtnStyle: 'basic',
      type: CustomOverlayType.DEFAULT,
      component,
      closeBtnClass: 'd-none',
      disposeOnNavigation: true,
      dialog: {
        type: data.dialog?.type,
        footerTemplate: data?.dialog?.footerTemplate,
      },
    });
    this.customOverlay.afterClosed.subscribe((result: any = {}) => {
      if (cb) {
        cb(result);
      }
    });

    return this.customOverlay;
  }
}
