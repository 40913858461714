<table
  #ref
  mat-table
  [dataSource]="dataSource | filterByQuery: query : activeSearch"
  matSort
  (matSortChange)="onSort($event)"
  [ngClass]="{
    showHeader: showHeader ? true : false
  }"
>
  <ng-container
    *ngFor="let tableColumn of tableColumns; let i = index"
    [matColumnDef]="tableColumn.dataKey"
  >
    <!-- if sortable column header -->
    <ng-container *ngIf="tableColumn.isSortable; else notSortable">
      <th
        mat-header-cell
        *matHeaderCellDef
        [mat-sort-header]="tableColumn.dataKey"
        [arrowPosition]="tableColumn.position === 'right' ? 'before' : 'after'"
        [ngStyle]="{
          width:
            tableColumn.width && tableColumn.width !== 'fit'
              ? tableColumn.width
              : undefined,
          flex: tableColumn.width && tableColumn.width === 'fit' ? 1 : undefined
        }"
      >
        <div
          class="header__container"
          [class.text-right]="tableColumn.position == 'right'"
          [class.text-center]="tableColumn.position == 'center'"
          [class.text-left]="tableColumn.position == 'left'"
        >
          {{ tableColumn.name || '' }}
          <fa-icon
            *ngIf="tableColumn.isFilterable"
            class="search--icon"
            [icon]="['far', 'search']"
            (click)="onSearch($event, tableColumn.dataKey)"
          ></fa-icon>
        </div>
        <div
          class="search__container"
          *ngIf="
            tableColumn.isFilterable && activeSearch === tableColumn.dataKey
          "
        >
          <itq-input
            [autoFocus]="true"
            [(ngModel)]="query"
            [placeholder]="'Search...'"
            (search)="onApplySearch()"
            (clear)="onApplySearch()"
          ></itq-input>
        </div>
      </th>
    </ng-container>
    <!-- else not sortable -->
    <ng-template #notSortable>
      <th
        mat-header-cell
        *matHeaderCellDef
        [class.text-right]="tableColumn.position == 'right'"
        [class.text-center]="tableColumn.position == 'center'"
        [class.text-left]="tableColumn.position == 'left'"
        [ngStyle]="{
          width:
            tableColumn.width && tableColumn.width !== 'fit'
              ? tableColumn.width
              : undefined,
          flex: tableColumn.width && tableColumn.width === 'fit' ? 1 : undefined
        }"
      >
        {{ tableColumn.name || '' }}
      </th>
    </ng-template>

    <!-- column data -->
    <td
      mat-cell
      *matCellDef="let element; let i = index"
      [class.text-right]="tableColumn.position === 'right'"
      [class.text-center]="tableColumn.position === 'center'"
      [class.text-left]="tableColumn.position === 'left'"
      [class.ellipsis]="!tableColumn.customRender"
      [ngStyle]="{
        width:
          tableColumn.width && tableColumn.width !== 'fit'
            ? tableColumn.width
            : undefined,
        flex: tableColumn.width && tableColumn.width === 'fit' ? 1 : undefined
      }"
    >
      <ng-container *ngIf="tableColumn.customRender && tableColumn.template">
        <ng-container
          *ngTemplateOutlet="
            tableColumn.template;
            context: { $implicit: element, index: i }
          "
        ></ng-container> </ng-container
      ><ng-container *ngIf="!tableColumn.customRender">
        {{ element | dataPropertyGetter: tableColumn.dataKey }}
      </ng-container>
    </td>
  </ng-container>
  <ng-container *ngIf="showHeader">
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  </ng-container>
  <tr
    mat-row
    *matRowDef="let row; columns: displayedColumns"
    [ngClass]="{
      success: row?.success,
      highlight: row?.highlight,
      error: row?.error
    }"
  ></tr>
  <tr *matNoDataRow>
    <itq-panel-info
      [label]="emptyMessage"
      [message]="emptyMessageDescription"
      [icon]="'exclamation-circle'"
    ></itq-panel-info>
  </tr>
  <ng-container matColumnDef="footerTemplate">
    <td
      mat-footer-cell
      *matFooterCellDef
      [attr.colspan]="displayedColumns?.length"
    >
      <ng-container *ngTemplateOutlet="footerTemplate"></ng-container>
    </td>
  </ng-container>
  <ng-container *ngIf="footerTemplate">
    <tr mat-footer-row *matFooterRowDef="['footerTemplate']"></tr>
  </ng-container>
</table>
