<div
  *ngIf="
    dataSource?.source_documents?.length > MIN_NUMBER_ITEMS;
    else noResultsTemplate
  "
  class="ripl-container"
  id="visualization"
></div>
<ng-template #noResultsTemplate>
  <div
    *ngIf="dataSource?.source_documents?.length <= MIN_NUMBER_ITEMS"
    class="no-foamtree-container"
  >
    <itq-panel-info
      [label]="'We don\'t have anything!'"
      [description]="'There are not enough documents to cluster'"
      [message]="'Change your search to get results'"
      [icon]="'hands-helping'"
    ></itq-panel-info>
  </div>
</ng-template>
