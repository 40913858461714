<input
  (dateChange)="onDateChange($event)"
  placeholder="Choose a date"
  readonly
  class="form-control"
  [matDatepicker]="datepicker"
  [(ngModel)]="dateValue"
/>

<itq-mat-button
  *ngIf="dateValue"
  [class]="'btn--delete'"
  [materialStyle]="'basic'"
  [type]="'button'"
  [disabled]="disabled"
  (mousedown)="onClear()"
>
  <fa-icon [icon]="['far', 'times']" [size]="'lg'"></fa-icon>
</itq-mat-button>
<mat-datepicker-toggle matIconSuffix [for]="datepicker"></mat-datepicker-toggle>
<mat-datepicker #datepicker>
  <mat-datepicker-actions>
    <button mat-button matDatepickerCancel>Cancel</button>
    <button mat-flat-button color="primary" matDatepickerApply>Apply</button>
  </mat-datepicker-actions>
</mat-datepicker>
