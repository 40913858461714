import {
  IPresetQuery,
  IQueryColumn,
  IQueryFilter,
  ISort,
} from '../interfaces/query-filters';

export class QueryFilters implements IQueryFilter {
  constructor(
    public pageSize: number,
    public page: number,
    public where: IPresetQuery,
    public query: string | Array<IQueryColumn>,
    public sort: ISort,
  ) {}

  public cloneDeep(): QueryFilters {
    return new QueryFilters(
      this.pageSize,
      this.page,
      this.where,
      this.query,
      this.sort,
    );
  }

  public addQueryColumn(column: IQueryColumn): void {
    if (typeof this.query === 'string') {
      this.query = [column];
    } else {
      const item = this.findQueryColumnByField(column);
      if (item) {
        this.query = (this.query as Array<IQueryColumn>).map(
          (item: IQueryColumn) => {
            if (item.searchField === column.searchField) {
              return column;
            } else {
              return item;
            }
          },
        );
      } else {
        this.query = [...((this.query as Array<IQueryColumn>) || []), column];
      }
    }
  }

  public removeQueryColumn(column: IQueryColumn): void {
    this.query = (this.query as Array<IQueryColumn>).filter(
      (item: IQueryColumn) => {
        return item.searchField !== column.searchField;
      },
    );
    if (this.query?.length === 0) {
      this.query = undefined;
    }
  }

  public findQueryColumnByField(column: IQueryColumn) {
    const columns = this.query as Array<IQueryColumn>;
    return columns?.find(
      (item: IQueryColumn) => item.searchField === column.searchField,
    );
  }

  public resetPagination(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.page = 1;
      resolve();
    });
  }

  public reset(): QueryFilters {
    return new QueryFilters(100, 1, undefined, undefined, undefined);
  }
}
