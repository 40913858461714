import { Component, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
  Align,
  CUSTOM_OVERLAY_DATA,
  CustomOverlayConfig,
  CustomOverlayRef,
  CustomOverlayService,
  DynamicPlaceholderDirective,
  IconType,
  QueryFilters,
  SearchFieldType,
  Utils,
  Sizes,
} from '@intorqa-ui/core';
import { cloneDeep } from 'lodash';
import { AddNotesFactory } from './add-notes.factory';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ProfileService } from '@portal/profiles/services/profiles.service';
import { NotesService } from '@portal/profiles/services/notes.service';
import { NoteTypes } from '@portal/profiles/enums/profile.enum';
import { NotesFactory } from '@portal/profiles/factories/notes.factory';
import {
  INotesData,
  IProfileNote,
} from '@portal/profiles/interfaces/profile-note.interface';
import { IProfileData } from '@portal/profiles/interfaces/profile.interface';
import { NoteType } from '@portal/profiles/models/note-type';
import { ProfileNote } from '@portal/profiles/models/profile-note';
import { ProfileType } from '@portal/profiles/models/profile-type';
import { Profile } from '@portal/profiles/models/profile';
import {
  WidgetActions,
  AnalysisTypes,
  ChartType,
} from '@portal/shared/enums/widget.enum';
import { NavigationHistoryItem } from '@portal/widget-settings/models/navigation-history-item.model';
import { ProfilesNavigationItem } from '@portal/widget-settings/models/profiles-navigation-item.model';
import { WidgetService } from '@portal/boards/services/widget.service';
import { UserService } from '@portal/shared/services/user.service';

@Component({
  selector: 'itq-add-notes',
  templateUrl: './add-notes.component.html',
  styleUrls: ['./add-notes.component.scss'],
  providers: [ProfileService],
})
export class AddNotesComponent implements OnInit {
  @Input() note: ProfileNote;
  @Input() documentId: string;
  @Input() profile: Profile;
  @Input() navigationItem: NavigationHistoryItem;
  @Input() form: FormGroup;
  @Input() action: WidgetActions;
  @Input() source: string;
  @Input() showTitle = false;

  public typesDataSource: Array<NoteType> = [];
  public notes: INotesData;
  public profilesDataSource: Array<Profile> = [];
  private profilesInitialState = new QueryFilters(30, 1, undefined, undefined, {
    direction: 'desc',
    active: 'updatedDate',
  });
  public profilesTypesDataSource: Array<ProfileType> = [];

  readonly WidgetActions = WidgetActions;
  readonly NoteTypes = NoteTypes;
  readonly Align = Align;
  readonly Sizes = Sizes;
  readonly IconType = IconType;
  readonly Validators = Validators;

  @ViewChild(DynamicPlaceholderDirective, { static: true })
  dynamicPlaceholder!: DynamicPlaceholderDirective;

  constructor(
    @Inject(CUSTOM_OVERLAY_DATA) public config: CustomOverlayConfig,
    private notesService: NotesService,
    readonly customOverlayRef: CustomOverlayRef,
    readonly profileService: ProfileService,
    readonly widgetService: WidgetService,
    readonly customOverlayService: CustomOverlayService,
    readonly snackBar: MatSnackBar,
    readonly userService: UserService,
  ) {
    this.notes = cloneDeep(this.notesService.notes);
  }

  ngOnInit() {
    this.profileService
      .getProfileTypes(this.userService.userPreferences.defaultEcosystemId)
      .subscribe((response: Array<ProfileType>) => {
        this.profilesTypesDataSource = response;
      });
    this.createForm();
    this.onLoadTypes();
    this.onGetProfiles();
    if (this.action === WidgetActions.SETTINGS) {
      const noteType = this.notesService.getTypeById(
        this.form.get('addNote.type').value,
      );
      this.createComponent(noteType);
    }
  }

  ngOnDestroy(): void {
    this.note = undefined;
    this.destroyForm();
  }

  public onDataBoundProfiles(params: QueryFilters): void {
    this.profilesInitialState.page = params.page;
    if (params.query) {
      this.profilesInitialState.addQueryColumn({
        searchValues: [params.query.toString()],
        searchField: 'name',
        searchFieldType: SearchFieldType.TEXT,
      });
    }
    this.onGetProfiles();
  }

  public onGetProfiles(): void {
    this.profilesInitialState.addQueryColumn({
      searchValues: [this.form?.get('addNote.profileType')?.value],
      searchField: 'typeId',
      searchFieldType: SearchFieldType.ID,
    });
    this.profileService
      .getProfiles(
        this.profilesInitialState,
        this.userService.userPreferences.defaultEcosystemId,
      )
      .subscribe((response: IProfileData) => {
        if (response.items?.length === 0) {
          this.form
            .get('addNote.profile')
            ?.removeValidators([Validators.required]);
        } else {
          this.form
            .get('addNote.profile')
            ?.addValidators([Validators.required]);
        }
        this.profilesDataSource = response.items;
      });
  }

  public onChangeProfile(): void {
    this.note = undefined;
    this.destroyForm();
  }

  private createComponent(item: NoteType): void {
    const viewContainerRef = this.dynamicPlaceholder.viewContainerRef;
    const component = AddNotesFactory.getComponent(item);
    let componentRef = viewContainerRef.createComponent<any>(component);
    componentRef.instance.form = this.form;
    componentRef.instance.profile = this.profile;
    componentRef.instance.note = this.note;
  }

  private createForm(): void {
    this.form.addControl('addNote', new FormGroup({}));
    const addNoteForm = this.form.get('addNote') as FormGroup;
    if (
      this.navigationItem?.action === WidgetActions.EXPLORE ||
      this.navigationItem.action === WidgetActions.SETTINGS
    ) {
      addNoteForm.addControl(
        'profileType',
        new FormControl(this.profile?.profileTypeId, [Validators.required]),
      );
      if (this.profile?.profileId) {
        addNoteForm.addControl(
          'profile',
          new FormControl(
            this.profile,
            this.profilesDataSource?.length > 0
              ? [Validators.required]
              : undefined,
          ),
        );
        if (this.profile?.profileTypeId) {
          addNoteForm.addControl(
            'type',
            new FormControl(this.note?.typeId, [Validators.required]),
          );
        }
      }
    } else {
      addNoteForm.addControl(
        'type',
        new FormControl(this.note?.typeId, [Validators.required]),
      );
    }
  }

  public onLoadTypes(): void {
    if (this.profile?.profileTypeId) {
      this.notesService
        .getNoteTypes(this.profile.profileTypeId)
        .subscribe((response: Array<NoteType>) => {
          this.typesDataSource = response;
          if (this.note?.typeId) {
            this.form.get('addNote.type').setValue(this.note.typeId);
          }
        });
    }
  }

  public onCancel(): void {
    this.notesService.notes = this.notes;
    this.onClose();
  }

  private onClose(): void {
    if (this.source === 'PostNotesWizardComponent') {
      const profile = new Profile(
        undefined,
        undefined,
        AnalysisTypes.PROFILE,
        undefined,
        undefined,
        ChartType.PROFILE,
        this.userService.userPreferences.defaultEcosystemId,
        this.form?.get('addNote.profileType')?.value,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
      );
      let params = {
        refresh: false,
      };

      if (!this.form?.get('addNote.profile')?.value) {
        params = {
          ...params,
          ...{
            profile,
            note: this.note,
          },
        };
      }
      this.customOverlayRef.close(params);
    }
    this.notesService.showAddNotes$.next();
  }

  public onAdd(): void {
    this.addNotes(true);
    this.onClose();
    this.form.controls.notes?.markAsTouched();
  }

  public onUpdate() {
    if (
      this.navigationItem.action === WidgetActions.EXPLORE ||
      this.navigationItem.action === WidgetActions.SETTINGS
    ) {
      if (this.note?.id) {
        this.notesService
          .updateNote(this.profile.profileId, this.note)
          .subscribe(() => {
            this.snackBar.open('Note has been updated successfully!', 'Close', {
              horizontalPosition: 'right',
              duration: 5000,
              verticalPosition: 'top',
            });
            this.onClose();
          });
      } else {
        const targetProfile = this.profile.cloneDeep();
        targetProfile.profileId =
          this.form?.get('addNote.profile')?.value?.profileId;
        this.notesService
          .addNotes(this.profile, targetProfile, [this.note])
          .subscribe(() => {
            this.notesService.notes$.next(this.notesService.notes);
            this.onClose();
          });
      }
    } else {
      this.notesService.notes$.next(this.notesService.notes);
      this.onClose();
    }
    this.form.controls.notes?.markAsTouched();
  }

  public onCreate(): void {
    this.addNotes(false);
    this.dynamicPlaceholder?.viewContainerRef?.clear();
    this.form.get('addNote').reset();
    if (this.source !== 'PostNotesWizardComponent') {
      this.form
        .get('addNote.profileType')
        .setValue(this.profile?.profileTypeId);
      this.form.get('addNote.profile').setValue(this.profile);
    }
    this.form.controls.notes?.markAsTouched();
  }

  private addNotes(close: boolean): void {
    if (
      this.navigationItem.action === WidgetActions.EXPLORE ||
      this.navigationItem.action === WidgetActions.SETTINGS
    ) {
      const targetProfile = this.profile.cloneDeep();
      targetProfile.profileId =
        this.form?.get('addNote.profile')?.value?.profileId;
      this.notesService
        .addNotes(this.profile, targetProfile, [this.note])
        .subscribe(() => {
          this.notesService.notes$.next(this.notesService.notes);
          this.notes = cloneDeep(this.notesService.notes);
          this.snackBar.open('Note has been added successfully!', 'Close', {
            horizontalPosition: 'right',
            duration: 5000,
            verticalPosition: 'top',
          });
          if (this.source === 'PostNotesWizardComponent' && close) {
            this.customOverlayRef.close();
          }
        });
    } else {
      this.notesService.notes.items.push(this.note);
      this.notesService.notes.totalCount += 1;
      this.notesService.notes$.next(this.notesService.notes);
      this.notes = cloneDeep(this.notesService.notes);
    }
  }

  private destroyForm(): void {
    this.dynamicPlaceholder?.viewContainerRef?.clear();
  }

  public onChangeType(value: string): void {
    this.destroyForm();
    if (this.action !== WidgetActions.SETTINGS) {
      this.note = undefined;
    }
    const noteType = this.notesService.getTypeById(value);
    const note = this.notesService.notes?.items?.find(
      (item: ProfileNote) => item.id === this.note?.id,
    );
    let id: string;
    if (note) {
      id = this.note.id;
    } else {
      id = Utils.generateUUID();
    }
    this.note = NotesFactory.generateNote(noteType.name, {
      id,
      profileId: this.form?.get('addNote.profile')?.value?.profileId,
      typeId: value,
      documentId: this.documentId,
    } as IProfileNote);

    this.createComponent(noteType);
  }

  public onChangeProfileType(profileTypeId: string): void {
    this.onGetProfiles();
    this.destroyForm();
    this.note = undefined;
    if (!this.profile) {
      this.profile = new Profile(
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        this.userService.userPreferences.defaultEcosystemId,
        profileTypeId,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
      );
    }
    const addNoteForm = this.form.get('addNote') as FormGroup;
    this.form?.get('addNote.profile')?.reset();
    this.form?.get('addNote.type')?.reset();
    if (!this.form.get('addNote.profile')) {
      addNoteForm.addControl(
        'profile',
        new FormControl(
          undefined,
          this.profilesDataSource?.length > 0
            ? [Validators.required]
            : undefined,
        ),
      );
    }
    this.onLoadTypes();
    if (!this.form.get('addNote.type')) {
      const addNoteForm = this.form.get('addNote') as FormGroup;
      addNoteForm.addControl(
        'type',
        new FormControl(undefined, [Validators.required]),
      );
    }
  }

  public onCreateProfile(): void {
    if (this.config?.component?.name !== 'DocumentItemTileComponent') {
      const profile = new Profile(
        undefined,
        undefined,
        AnalysisTypes.PROFILE,
        undefined,
        undefined,
        ChartType.PROFILE,
        this.userService.userPreferences.defaultEcosystemId,
        this.form?.get('addNote.profileType')?.value,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
      );
      const navigationItem = new ProfilesNavigationItem(
        `${WidgetActions.CREATE}_addnote_profiles`,
        profile,
        WidgetActions.CREATE,
        undefined,
        undefined,
        this.form,
        'plus',
        IconType.FONT_AWESOME,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        [this.note],
        0,
      );
      this.widgetService.drilldownObservable.next(navigationItem);
    }
    this.onCancel();
  }
}
